import React, { Component } from 'react'
import ReactToPrint from "react-to-print";
import { Api, api } from '../../../helper/Api';
import {Input, Choose} from '../../../components/Form'
class Report extends Component
{

  
  render()
  {
   
    return(
      <div>
        <div class="row">
                        <div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-header p-4">

                                {this.props.company.details.map(data => (
                                  <img height="80" width="80" src={`${api}/uploads/${data.LOGO}`} alt=""/>
                                ))}
                                    
                                    <div class="float-right">   <h3><a class="pt-2 d-inline-block" href="/" >{this.props.company.orgName}</a></h3>
                                   <h4 class="mb-0">DEDUCTIONS REPORT</h4>
                                   <h4 class="mb-0">KRA PIN : {
                                      this.props.company.details.map(data => (
                                        <span>{data.KRA_PIN}</span>
                                      ))
                                    }</h4>
                                   {this.props.date}</div>
                                </div>
                                <div class="card-body">
                                   
                                    <div class="table-responsive-sm">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="center">EMP ID</th>
                                                    <th>EMPLOYEE NAME</th>
                                                    <th class="center">TOTAL DEDUCTIONS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                          this.props.sheet.map(data => (
                              <tr>
                                  <td>{data.id}</td>
                                  <td>{data.name}</td>
                                 
                                  <td>
                                   <table class="table table-striped">  <tr>{data.deductTbl.map(tbl =>(<tr>{Object.keys(tbl).map(key=>(
                                    <tbody><td>{key} </td> <td>{tbl[key]} </td></tbody>
                                   ))}  </tr>))}</tr><tbody><td>OTHERS  </td> <td>{data.custom} </td></tbody>
                                   <tr>{data.allowanceTbl.map(data =>(<td>{Object.keys(data).map(key=>(
                                    <tbody></tbody>
                                   ))}</td>))} <td>Total</td> <td>{data.deduction}</td></tr> 
                                  
                                  
                                  </table> </td>
    
                              </tr>
                          ))
                      }
                                            </tbody>
                                        </table>
                                    </div>
                                   
                                </div>
                                <div class="card-footer bg-white">
                                    <p class="mb-0">{
                                      this.props.company.details.map(data => (
                                        <span>Location: {data.LOCATION} CONTACT: {data.CONTACT}</span>
                                      ))
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
      </div>
    )
  }
}
export default class deductions extends Component {

  constructor()
  {
    super()
    this.state = {
      company: {details: [], orgName:""},
      date: new Date(),
      sheet: [],
      gross: "",
      month:"",
      year: ""
    }
  }


  mountFunction()
  {
    //get organization Details
    const postData = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem('Token')
      })
    }
    Api("/workspace/manage/find", postData).then(
      data => {
        this.setState({
          company: data
        })
      }
    )

    Api('/payroll/manage/payroll', postData).then(data => {
      this.setState({sheet: data.payrollData, gross: data.grossTotal})
  })
    //get Payroll Details
  }
  render() {
    
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({ value: month, label: month }))
    return (
      <div>
        <h3>Summary Of Employee Deductions</h3>
        < div className="col-md-6" >
          <Choose options={monthOpt} label="Choose Month" onchange={
            e => {
              this.setState({
                month: e.value
              })
             }
          } />
          <Input label="Choose Year" ph="Year" onchange={
            e => {
              this.setState({
                year: e.target.value
              })
            }
          } />

          <button className="btn btn-primary" onClick={
            e => {
              const postData = {
                method: 'POST',
                body: JSON.stringify({
                  token: "Bearer " + localStorage.getItem('Token'),
                  month: this.state.month,
                  year: this.state.year
                })
              }
              Api("/workspace/manage/find", postData).then(
                data => {
                  this.setState({
                    company: data
                  })
                }
              )

              Api('/payroll/manage/payroll', postData).then(data => {
                this.setState({ sheet: data.payrollData, gross: data.grossTotal })
              })
            }
          }>Get Deductions</button>
        </div >

        <hr/>

        <ReactToPrint 
          trigger={() =>  <button className="btn btn-info">PRINT</button>}
          content={() => this.componentRef}
        />
       
       <Report date={this.state.date.toString()} sheet={this.state.sheet} gross={this.state.gross} company={this.state.company} ref={el => (this.componentRef = el)} />
                
      </div>
    )
  }
}

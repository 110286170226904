import React, { Component } from 'react'
import Select from 'react-select'
import {Api} from '../../../helper/Api'
import { modules } from '../../../components/Sidenav/Routes';

export default class Access extends Component {
  constructor()
  {
    super()
    this.state = {
      groupName: "",
      alert: "",
      message: "",
      groups:[],
      accessArray: [],
      activeAccess: "",
      username: "",
      password: "",
      groupId: "",
      accounts: []
    }
    
    this.textchange = this.textchange.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.toggleAccess = this.toggleAccess.bind(this)
  }

  componentWillMount()
  {
    this.fetchData()
  }
  toggleAccess(e)
  {
    var virtualArray
    if(e.target.checked === true)
    {
      virtualArray  = this.state.accessArray
      virtualArray.push(e.target.name)
      this.setState({accessArray: virtualArray})
    }else{
      virtualArray  = this.state.accessArray
      virtualArray.splice(virtualArray.indexOf(e.target.name))
      this.setState({accessArray: virtualArray})
    }
  }
  fetchData()
  {
    const postData = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }

    Api("/auth/account/groups", postData).then(data => {
      this.setState({
        groups: data
      })
    })

    Api("/auth/account/accounts", postData).then(data => {
      this.setState({accounts: data})
    })
  }
   textchange(e)
   {
     this.setState({
       [e.target.name]: e.target.value
     })
   }
  render() {
    console.log(this.state)
    return (
      <div>
        <h4>Access And Account Management</h4>
        <div className={`${this.state.alert} col-md-6`}>
          <strong>{this.state.message}</strong>
        </div>
        <div className="accordion-regular">
        <div id="accordion-3">
        <div className="card">

        <div className="card-header">
          <h5 className="mb-0">
          <button className="btn btn-primary btn-lg" data-toggle="collapse" data-target="#newAcType" aria-expanded="false" aria-controls="collapseSeven">New Users Group</button>
          </h5>
          <div id="newAcType" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3"><div class="card-body row">
        <div className="card-body col-md-6">
       
            <div className="form-group">
              <label htmlFor="">Enter Group Name</label>
              <input type="text" placeholder="Enter Access Groups"  onChange={this.textchange} className="form-control" name="groupName" id=""/>


            <button className="btn btn-sm btn-success" onClick={(e)=>{
              const postData = {
                method: 'POST',
                body: JSON.stringify({
                  token: "Bearer "+localStorage.getItem("Token"),
                  groupName: this.state.groupName
                })
              }

              Api("/auth/account/access", postData).then(data =>{this.setState({alert: "alert alert-info", message: data.message})
            this.fetchData()
            })
              
            }}>Save</button>
            </div>

            <div>
              <table className="table table-bordered table-striped">
                <tr>
                  <th>Group Name</th>
                  <th>Actions</th>
                </tr>
                <tbody>
                  {
                    this.state.groups.map(data => (
                      <tr>
                        <td>{data.ACCESS_NAME}</td>
                        <td><button id={data.ACCESS_ID} onClick={(e)=> {
                           const postData = {
                            method: 'POST',
                            body: JSON.stringify({
                              token: "Bearer "+localStorage.getItem("Token"),
                              accessId: e.currentTarget.id
                            })
                          }

                          Api('/auth/account/deleteaccess', postData).then(data => {
                            this.setState({
                              alert: "alert alert-info",
                              message: data.message
                            })
                            this.fetchData()
                          })

                        }} className="btn btn-sm btn-danger">Delete</button></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
        </div>
          </div></div>
        </div>
          </div> 
          <div className="card">

        <div className="card-header">
          <h5 className="mb-0">
          <button className="btn btn-primary btn-lg" data-toggle="collapse" data-target="#access" aria-expanded="false" aria-controls="collapseSeven">Set Group Access</button>
          </h5>
          <div id="access" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3"><div class="card-body row">
          <div className="card-body col-md-6">
            <div className="form-group">
             <label htmlFor="">Choose Group</label>
             <Select options={this.state.groups.map(data => ({value: data.ACCESS_ID, label:data.ACCESS_NAME}))} onChange={(e)=>{
               this.setState({
                 activeAccess: e.value
               })
             }} />
            </div>
            <br/> <h3>Choose Modules To Restrict</h3>
             {
               modules[0].modules.map(data => (
                <label className="custom-control custom-checkbox">
                   <input type="checkbox" className="custom-control-input" onChange={this.toggleAccess} name={data.id} id=""/><span class="custom-control-label">{data.name}</span>
                </label>
               ))
             }

            <button className="btn btn-sm btn-success" onClick={
              (e)=>{
                const postData = {
                  method: 'POST',
                  body: JSON.stringify({
                    token: "Bearer "+localStorage.getItem("Token"),
                    privillage: this.state.accessArray,
                    group: this.state.activeAccess
                  })
                }

                Api('/auth/account/privillage', postData).then(data => {
                  this.setState({
                    alert: "alert alert-info",
                    message: data.message
                  })
                })
              }
            } >Save</button>
          </div>

         
          </div></div>
        </div>
          </div> 

          <div className="card">

        <div className="card-header">
          <h5 className="mb-0">
          <button className="btn btn-primary btn-lg" data-toggle="collapse" data-target="#newUser" aria-expanded="false" aria-controls="collapseSeven">New User Account</button>
          </h5>
          <div id="newUser" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3"><div class="card-body row">
          <div className="card-body col-md-6">
          <div className="form-group">
            <label htmlFor="">Username</label>
            <input type="text" className="form-control" placeholder="Enter username" name="username" onChange={this.textchange} id=""/>

            <label htmlFor="">Password</label>
            <input type="Password" className="form-control" placeholder="Enter password"  name="password" onChange={this.textchange} id=""/>
            <label htmlFor="">Choose Group</label>
            <Select options={this.state.groups.map(data => ({value: data.ACCESS_ID, label:data.ACCESS_NAME}))} onChange={(e)=>{this.setState({groupId:e.value})}}  />

            <button className="btn btn-sm btn-success" onClick={
              (e)=>{
                const postData = {
                  method: 'POST',
                  body: JSON.stringify({
                    token: "Bearer "+localStorage.getItem("Token"),
                    username: this.state.username,
                    password: this.state.password,
                    accountType: this.state.groupId
                  })
                }

                Api('/auth/account/newaccount', postData).then(data => {
                  this.setState({
                    alert: "alert alert-primary", message: data.message
                  })
                  this.fetchData()
                })
              }
            }>Save</button>
          </div>
          </div>

          </div></div>
        </div>
          </div> 

        </div>
        </div>

        <hr/>
 
        <div className={`alert alert-${this.state.alert}`}>
            <strong>{this.state.message}</strong>
        </div>
        <table className="table-stripped table-bordered table">
            <tr>
              <th>USERNAME</th>
              <th>PASSWORD</th>
              <th>ACTION</th>
              
            </tr>

            {
              this.state.accounts.map(data=>(
                <tr>
                  <td contentEditable onBlur={(e , id=data.USER_ID)=>{
                    const postData ={
                      method: "POST",
                      body: JSON.stringify({
                        token: "Bearer " + localStorage.getItem("Token"),
                        updateCol: 'USERNAME',
                        updateVal: e.currentTarget.textContent,
                        id: id
                      })
                      
                    }

                    Api('/auth/account/recover', postData).then(data=>{
                      this.setState({
                        alert : "success",
                        message: data.message
                      })
                    })
                  }}>{data.USERNAME}</td>
                  <td contentEditable onClick={e=>{
                    e.currentTarget.textContent = ""
                  }} onBlur={(e, id = data.USER_ID) => {
                    const postData = {
                      method: 'POST',
                      body:JSON.stringify({
                        token: "Bearer " + localStorage.getItem("Token"),
                        updateCol: 'PASSWORD',
                        updateVal: e.currentTarget.textContent,
                        id: id
                      })
                      
                    }

                    if(e.currentTarget.textContent === "")
                    {
                      e.currentTarget.textContent = "*******"
                    } else {
                      Api('/auth/account/recover', postData).then(data => {
                        this.setState({
                          alert: "success",
                          message: data.message
                        })
                        
                      })
                      e.currentTarget.textContent = "*******"
                    }

                   
                  }}>*******</td>
                  <td>
                    <button className="btn btn-danger" onClick={
                      (e,uid=data.USER_ID)=>{
                        const postData = {
                          method: 'POST',
                          body: JSON.stringify(
                            {
                              token: `Bearer ${localStorage.getItem('Token')}`,
                              userId: uid
                            }
                          )

                        }

                        Api('/auth/account/deleteaccounts', postData).then(data=>{
                          this.fetchData()
                          this.setState({
                            alert: "danger",
                            message: data.message
                          })
                        })
                      }
                    }>Delete</button>
                  </td>
                  
                </tr>
              ))
            }
        </table>
      </div>
    )
  }
}

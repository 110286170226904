import React, { Component } from 'react'

export default class OverView extends Component {
  render() {
    return (
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="card">
                <div class={'card-body bg-'+this.props.bg}>
                    <div class="d-inline-block">
                        <h5 class="text-muted h5">{this.props.text}</h5>
                        <h2 class="mb-0 h2" > {this.props.value}</h2>
                    </div>
                    <div class="float-right icon-circle-medium  icon-box-lg  bg-info-light mt-1">
                        <i class={"fas fa-"+this.props.icon+" fa-fw fa-sm text-info"}></i>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

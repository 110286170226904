import React from 'react'
import { Link } from 'react-router-dom';
import {getSessionPayload} from "../../helper/Session"
import { api } from '../../helper/Api';
function logout()
{
  localStorage.clear();
  window.location.href ="/";
}
export default function NavBarAuthed(props) {
  return (
    <div class="dashboard-header">
      <nav class="navbar navbar-expand-lg bg-white fixed-top">
        <Link class="navbar-brand" to="/">HRMS</Link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto navbar-right-top">

                                  <li class="nav-item dropdown nav-user">
                                    <a class="nav-link nav-user-img" href id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={`${api}/uploads/${getSessionPayload().avatar}`} alt="AVATAR" class="user-avatar-md rounded-circle"/></a>
                                      <div class="dropdown-menu dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
                                        <div class="nav-user-info">
                                          <h5 class="mb-0 text-white nav-user-name">{getSessionPayload().username} </h5>
                                          <span class="status"></span><span class="ml-2">Logged in as <b>{getSessionPayload().rank}</b></span>
                                        </div>
                                        <Link to="/account" class="dropdown-item" href><i class="fas fa-user mr-2"></i>Account</Link>
                                        {getSessionPayload().rank === "Employee" ? "":<Link to="/settings/workspace" class="dropdown-item" href><i class="fas fa-user mr-2"></i>workspace Settings</Link>}
                                        <a class="dropdown-item" onClick={logout} href="#logout"><i class="fas fa-power-off mr-2" ></i>Logout</a>
                                      </div>
                        </li>
                    </ul>
                                </div>
            </nav>
                            </div>

  )
}

import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import TaxTypeManagement from './Tax/TaxTypeManagement'
import PersonalRelief from './Tax/PersonalRelief'
import TaxRates from './Tax/TaxRates'
import P9Form from './Tax/P9Form'
import ModularTaxReport from './Tax/ModularTaxReport'

export default class Taxation extends Component {
    render() {
        return (
            <div className="dashboard-wrapper bg-white">
            <div className="dashboard-ecommerce">
                <div className="container-fluid dashboard-content ">
                <PageHeader title="TAXATION MODULE" />
                    <Switch>
                        <Route exact path={this.props.match.path} component={TaxTypeManagement} />
                        <Route path={this.props.match.path + "/relief"} component={PersonalRelief} />
                        <Route path={this.props.match.path + "/manage"} component={TaxTypeManagement} />
                        <Route path={this.props.match.path + "/rules"} component={TaxRates} />
                        <Route path={this.props.match.path + "/p9"} component={P9Form} />
                        <Route path={this.props.match.path + "/tax_report"} component={ModularTaxReport} />
                    </Switch>
                </div>
            </div>
        </div>
        )
    }
}

import React, { Component } from 'react'
import ReactToPrint from "react-to-print";
import { Api, api } from '../../../helper/Api';
import {Choose, Input} from '../../../components/Form'
import {Messages} from 'primereact/messages';
import {Message} from 'primereact/message';

class Report extends Component
{

  
  render()
  {
   

    return(
      <div>
        {this.props.sheet.map(data =>(
            <div class="row">
            <div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card">
                    <div class="card-header p-4">

                    {this.props.company.details.map(data => (
                      <img height="80" width="80" src={`${api}/uploads/${data.LOGO}`} alt=""/>
                    ))}
                        
                        <div class="float-right">   <h3><a class="pt-2 d-inline-block" href="/" >{this.props.company.orgName}</a></h3>
                       <h4 class="mb-0">End Of Year Tax Return Form (P9 Form)</h4>
                       <h4 class="mb-0">EMPLOYER TAX PIN : {
                          this.props.company.details.map(data => (
                            <span>{data.KRA_PIN}</span>
                          ))
                          } / TAX YEAR {this.props.taxy}</h4>Form Generated at
                       {this.props.date}</div>
                    </div>
                    <div class="card-body">
                    
                     <div className="row">
                       <div className="col-md-6">
                       TAX PAYER'S NAME: {data.NAME}
                       </div>
                       <div className="col-md-6">

                      TAX PAYER'S ID: {data.ID_NUMBER}
                       </div>
                     </div>
                    
                    <div className="row">
                      <div className="col-md-6">
                        EMPLOYEE NUMBER: {data.employeeID}
                      </div>
                      <div className="col-md-6">  
                      EMPLOYEE TAX  ID: {data.companyKRA}
                      </div>
                    </div>

                    
                        <div class="table-responsive-sm">
                            <table class="table table-striped">
                                <thead>
                                   
                                    <tr>
                                        <th class="center">MONTH</th>
                                        {data.taxTypes.map(datas =>(
                                          <th>{datas.TAX_NAME}</th>
                                        ))}
                                        <th>TOTAL TAX</th>
                                        <th>PERSONAL RELIEF</th>
                                        <th>TAXABLE_AMOUNT</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {data.months.map(datam =>(
                                  <tr>
                                    <td>{datam}</td>
                                    {data.tax[datam].map(vals =>(
                                      <td>
                                        {Object.values(vals)}
                                      </td>
                                    ))}
                                    
                                  </tr>
                                ))}
                                <tr>
                                  <td>TOTALS</td>
                                  {data.taxSum.map(data=>(
                                    <td>{data}</td>
                                  ))}
                                  <td>{data.totalTax}</td>
                                  <td>{data.totalRelief}</td>
                                  <td>{data.totalTaxable}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-sm-5">
                            </div>
                            <div class="col-lg-4 col-sm-5 ml-auto">
                                <table class="table table-clear">
                                    <tbody>
                                        <tr>
                                            <td class="left">
                                                          </td>
                                            <td class="right"></td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-white">
                        <p class="mb-0">{
                          this.props.company.details.map(data => (
                            <span>Location: {data.LOCATION} CONTACT: {data.CONTACT}</span>
                          ))
                        }</p>
                    </div>
                </div>
            </div>
        </div>
                    ))}
      </div>
    )
  }
}
export default class P9Form extends Component {

  constructor()
  {
    super()
    this.state = {
      company: {details: [], orgName:""},
      date: new Date(),
      sheet: [],
      month: "",
      year: ""
    }

    this.mountFunction = this.mountFunction.bind(this)
  }

  componentWillMount()
  {
    this.mountFunction()
  }
  mountFunction()
  {
    //get organization DetailsPayroll
    const postData = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem('Token')
      })
    }
    Api("/workspace/manage/find", postData).then(
      data => {
        this.setState({
          company: data
        })
      }
    )


    //get Payroll Details
  }
  render() {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({ value: month, label: month }))
    
    return (
      <div>
        <h3>GENERATE P9 FORMS</h3>
        <ReactToPrint 
          trigger={() =>  <button className="btn btn-info">PRINT</button>}
          content={() => this.componentRef}
        />
        < div className="col-md-6" >
         
          <Input label="Choose Year" ph="Year" onchange={
            e => {
              this.setState({
                year: e.target.value
              })
            }
          } />

          <button className="btn btn-primary" onClick={
            e => {
              this.messages.show({ severity: 'info', summary: 'Generating Form',  })
              const postData = {
                method: 'POST',
                body: JSON.stringify({
                  token: "Bearer " + localStorage.getItem('Token'),
                  year: this.state.year
                })
              }
              Api('/taxation/manage/p9', postData).then(data => {
                this.setState({sheet: [], gross: []})
                this.setState({ sheet: data})
                this.messages.show({ severity: 'success', summary: 'Forms are generated',  })
              })
            }
          }>Get P9 FORMS</button>
        </div >
        <Messages ref={(el) => this.messages = el}></Messages>

      <Report taxy={this.state.year} date={this.state.date.toString()} sheet={this.state.sheet} gross={this.state.gross} company={this.state.company} ref={el => (this.componentRef = el)} />
   
      </div>
    )
  }
}

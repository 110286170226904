import React, { Component } from 'react'
import { api, Api } from '../../../helper/Api';

export default class Workspace extends Component {
  constructor()
  {
    super()
    this.state = {
     LOGO: "",
     title: "",
     CONTACT: "",
     location: "",
     KRA_PIN: "",
     alert: "",
     message: ""
    }
    this.fetchData = this.fetchData.bind(this)
    this.updateInfo = this.updateInfo.bind(this)
    this.photoChange= this.photoChange.bind(this)
  }

  componentWillMount()
  {
    this.fetchData()
  }

  photoChange(e)
  {
      const file = e.target.files
      const FileType = file[0].type

      if(FileType === "image/png")
      {
        const reader = new FileReader();
        reader.readAsDataURL(file[0])
  
        reader.onload = (e) => {
            //console.log(e.target.result);
            var preview = document.getElementById('preview')
            preview.src = e.target.result
  
            const postData = {
              method: 'POST',
              body: JSON.stringify({
                token: "Bearer "+localStorage.getItem("Token"),
                photoData: e.target.result
              })
            }
            
            Api('/workspace/manage/image', postData).then(
              data => {
                this.fetchData()
                this.setState({
                  alert: "alert alert-info",
                  message: data.message
                })
              }
            )
        }
      } else {
        this.setState({
          alert: "alert alert-warning",
          message: "Must Be An Image Or if image must be of *.png format"
        })
      }

      
      
  }

  fetchData()
  {
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }
    Api('/workspace/manage/find', data).then(data => {
      var info = data.details[0]
      this.setState({
        LOGO: info.LOGO,
        CONTACT: info.CONTACT,
        location: info.LOCATION,
        KRA_PIN: info.KRA_PIN,
        title: data.orgName
      })
    })
  }

  updateInfo(e)
  {
    const el = e.target
    const postData = {
      method: 'POST',
      body:JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token"),
        updateColumn: el.name,
        updateValue: el.value
      })
    }

    Api("/workspace/manage/edit", postData).then(data => {
      this.fetchData()
      
    })
  }
  render() {

    const state = this.state

    return (
      <div>
       <h3>Workspace Settings</h3>
       <img id="preview" height="100" width="100" src={`${api}/uploads/${state.LOGO === "" ? 'default.png' : state.LOGO}`} alt="LOGO"/>
       
       <div className="form-group col-md-6">
       <div className={this.state.alert}>
       <strong>{this.state.message}</strong>
       </div>
       <label htmlFor="">Change Logo</label>
       <input onChange={this.photoChange} type="file" className="form-control" name="" id=""/>
       <br/>
       <label htmlFor="">Company Name</label>
       <input type="text" value={state.title} onChange={(e)=>{this.setState({[e.target.name]: e.target.value})}} onBlur={this.updateInfo} className="form-control" name="title" id=""/>

       <label htmlFor="">Company Contacts</label>
       <input type="textarea" onChange={(e)=>{this.setState({[e.target.name]: e.target.value})}} value={state.CONTACT} onBlur={this.updateInfo} className="form-control" name="CONTACT" id=""/>
       <label htmlFor="">Company Address</label>
       <input type="text" onChange={(e)=>{this.setState({[e.target.name]: e.target.value})}} value={state.location} onBlur={this.updateInfo} className="form-control" name="location" id=""/>
       <label htmlFor="">Company KRA Pin</label>
       <input type="text" onChange={(e)=>{this.setState({[e.target.name]: e.target.value})}} value={state.KRA_PIN} onBlur={this.updateInfo} className="form-control" name="KRA_PIN" id=""/>

       <br/>
      <h4>Automatically Saves</h4>
       </div>
      </div>
    )
  }
}

import React, { Component } from 'react'

import { Api } from '../../../helper/Api'
import { Input, Choose } from '../../../components/Form'


export default class Employee extends Component {
   constructor()
  {
    super()
    this.state = {
      employeeData: [],
      departments: [],
      allowances: [],
      empId: "",
      allowance: "",
      amount: "",
      alert: "",
      icon: "",
      message: "",
      empData: []
      
    }
    this.Init = this.Init.bind(this)
    this.save = this.save.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.textChange = this.textChange.bind(this)
  }
  componentWillMount()
  {
    this.Init()
  }
  
  Init()
  {
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }

    Api('/employees/manage/list', data).then(data => 
      {
        this.setState({employeeData: data});
      })
    Api('/allowance/manage/list', data).then(data => {
      this.setState({ allowances: data })
    })
  }


  save()
  {
    const state = this.state
    const data = {
      method: 'POST',
      body: JSON.stringify(
        {
          token: "Bearer "+localStorage.getItem("Token"),
          amount: state.amount,
          allowance: state.allowance,
          empId: state.empId,
          month: this.state.month,
          year: this.state.year         
        }
      )
    }

    Api("/allowance/manage/allocate", data).then(data => {
      this.setState({
        alert: "alert alert-primary",
        message: data.message
      })
      var fetchData = {
        method: 'POST',
        body: JSON.stringify({
          token: "Bearer " + localStorage.getItem("Token"),
          empId: this.state.empId,
          year: this.state.year,
          month: this.state.month
        })
      }

      Api('/allowance/manage/one', fetchData).then(data => { this.setState({ empData: data }) })
    })
  }

  handleChange(e)
  {
    this.setState({
      allowance: e.value
    })
  }
  textChange(e)
  {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const {  allowances, empData } = this.state
    const options = allowances.map(data => (
      { value: data.ALLOWANCE_ID, label: data.ALLOWANCE_NAME}
    ))
    
    

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({value:month, label:month}))

    return (
      <div>
           
        <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="section-block">
            <h5 class="section-title">Info</h5>
            <p>Manage Employee Allowances</p>
          </div>
         
          <hr/>
          {
            this.state.employeeData.length < 1 ? <b>No Employees Avaliable Please Add Employees</b> : <span></span>
          }
          <div className={this.state.alert}>{this.state.message}</div>
          <div class="accrodion-regular">
            <div id="accordion3">
              {
                this.state.employeeData.map(data => (
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" name={data.EMPLOYEE_ID} onClick={(e)=>{
                          this.setState({empId: data.EMPLOYEE_ID, year: "", month: ""})
                        }} data-target={"#"+data.EMPLOYEE_ID} aria-expanded="false" aria-controls="collapseSeven">
                            <button className="btn btn-xs btn-info"> SET ALLOWANCES</button>
                                               </button>
                        EMPLOYEE ID:{data.EMPLOYEE_ID} | EMPLOYEE NAME: {data.NAMES} 
                      </h5>

                     
                    </div>
                    <div id={data.EMPLOYEE_ID} class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3">
                      <div className="col-md-6">
                        <Choose options={monthOpt} label="Choose Month" onchange={
                          e => {
                            this.setState({
                              month: e.value
                            })

                            console.log(e.value);
                            
                          }
                        } />
                        <Input label="Choose Year" ph="Year" onchange={
                          e => {
                            this.setState({
                              year: e.target.value
                            })
                          }
                        } />
                        <button className="btn btn-primary" onClick={
                          e=>{
                            var fetchData = {
                              method: 'POST',
                              body: JSON.stringify({
                                token: "Bearer " + localStorage.getItem("Token"),
                                empId: data.EMPLOYEE_ID,
                                year: this.state.year,
                                month: this.state.month
                              })
                            }

                            Api('/allowance/manage/one', fetchData).then(data => { this.setState({ empData: data }) })
                          }
                        }>Generate Allowance List</button>
                      </div>
                      <hr/>
                      <div class="row">
                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="section-block" id="basicform">
                            <h3 class="section-title">New Allowances</h3>
                            <p>Manage Allowances</p>
                          </div>
                          <div class="card">
                            <h5 class="card-header">Set Allowance</h5>
                            <div class="card-body">
                              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                              <Choose options={options} label="CHOOSE ALLOWACE TYPE" onchange={this.handleChange} ph="Select ALLOWANCE TYPE" />
                              <Input onchange={this.textChange} name="amount" ph="Enter AMOUNT" type="number" />
                              <button onClick={this.save} className="btn btn-secondary"> Save</button>
                            </div>
                            <div class="card-body border-top">

                            </div>
                          </div>
                        </div>

                        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class="section-block" id="basicform">
                            <h3 class="section-title">List  of Allowances For {data.EMPLOYEE_ID} </h3>
                           
                          </div>
                          <div class="card">
                            <h5 class="card-header">List Of Allowance <span class=" "></span></h5>
                            <div class="card-body">
                              <ul class="list-group list-group-flush">
                                {
                                  empData.length < 1 ? <li class="list-group-item">No Allowance Available Add One On The Left Panel</li> : empData.map(data => (
                                    <li class="list-group-item">{empData.indexOf(data)}) ALLOWANCE:{data.ALLOWANCE_NAME}, AMOUNT:{data.AMOUNT}<button onClick={
                                      (e, del=data.ID) => {
                                        const postData = {
                                          method: 'POST',
                                          body: JSON.stringify({
                                            token: "Bearer "+localStorage.getItem("Token"),
                                            deleteId: del
                                          })
                                        }

                                        Api('/allowance/manage/deleteemp', postData).then(data => {
                                          this.setState({
                                            alert: "alert alert-danger",
                                            message: data.message
                                          })
                                          var fetchData = {
                                            method: 'POST',
                                            body: JSON.stringify({
                                              token: "Bearer " + localStorage.getItem("Token"),
                                              empId: this.state.empId,
                                              year: this.state.year,
                                              month: this.state.month
                                            })
                                          }

                                          Api('/allowance/manage/one', fetchData).then(data => { this.setState({ empData: data }) })
                                        })
                                      }
                                    } className="btn btn-danger btn-sm text-white float-right"><span className="fas fa-trash"></span></button></li>
                                  ))
                                }


                              </ul>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
                         </div>
          </div>
        </div>

      </div>
    )
  }
}

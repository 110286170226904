import React, { Component } from 'react'
import PageHeader from '../../components/PageHeader';
import Switch from 'react-router-dom/Switch';
import { Route } from 'react-router-dom';
import List from './Divisions/List';
import Add from './Divisions/Add'
import Edit from './Divisions/Edit';
import Delete from './Divisions/Delete';

export default class Divisions extends Component {
  render() {
    return (
        <div className="dashboard-wrapper bg-white">
            <div className="dashboard-ecommerce">
                <div className="container-fluid dashboard-content ">
                <PageHeader title="DIVISIONS" />
                    <Switch>
                        <Route exact path={this.props.match.path} component={List} />
                        <Route path={this.props.match.path+"/new"} component={Add} />
                        <Route path={this.props.match.path + "/edit"} component={Edit} />
                        <Route path={this.props.match.path + "/delete"} component={Delete} />
                    </Switch>
                </div>
            </div>
        </div>
    )
  }
}

import React, { Component } from 'react'
import PageHeader from '../../components/PageHeader';
import Switch from 'react-router-dom/Switch';
import { Route } from 'react-router-dom';
import List from './Allowances/List';
import Add from './Allowances/Add'
import Employee from './Allowances/Employee';
import Delete from './Allowances/Delete';
import Relief from './Allowances/Relief';
                

export default class ALLOWANCES extends Component {
  render() {
    return (
        <div className="dashboard-wrapper bg-white">
            <div className="dashboard-ecommerce">
                <div className="container-fluid dashboard-content ">
                <PageHeader title="ALLOWANCES MODULES" />
                    <Switch>
                        <Route exact path={this.props.match.path} component={List} />
                        <Route path={this.props.match.path+"/types"} component={Add} />
                        <Route path={this.props.match.path + "/employee"} component={Employee} />
                        <Route path={this.props.match.path + "/delete"} component={Delete} />
                        <Route path={this.props.match.path + "/relief"} component={Relief} />
                    
                    </Switch>
                </div>
            </div>
        </div>
    )
  }
}

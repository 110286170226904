import React, { Component } from 'react'
import { Api } from '../../../helper/Api';
import { Input, Choose } from '../../../components/Form';

export default class Add extends Component {
  constructor() {
    super()
    this.state = {
      divisionId: "",
      department: "",
      departments:[],
      divisions: [],
      alert: "",
      icon: "",
      message: ""
    }
    this.textChange = this.textChange.bind(this)
    this.save = this.save.bind(this)
    this.InitFetch = this.InitFetch.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  componentWillMount() {
    this.InitFetch()

  }

  InitFetch() {
    const data = {
      method: 'POST',
      body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token") })
    }
    Api('/division/manage/list', data).then(data => {
      this.setState({ divisions: data })
    })
   Api('/departments/manage/list', data).then(data => {
      this.setState({departments: data})
    })
  }
  textChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  save() {

    const state = this.state
    if (state.division === "") {
      this.setState({ alert: "alert alert-warning", icon: "fas fa-exclamation", message: "Fill In The Field" });
    } else {
      this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Saving..." });
      const data = {
        method: 'POST',
        body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token"), division: state.divisionId, department: state.department })
      }
      Api("/departments/manage/add", data).then(data => {
        this.setState({ alert: "alert alert-primary", icon: "fas fa-check", message: data.message })
      })
      this.InitFetch()
    }
  }
  handleChange(selected)
  {
    this.setState({divisionId: selected.value})
  }
  render() {
    const { divisions, departments } = this.state
    const options = divisions.map(data =>(
      {value: data.DIVISION_ID , label: data.DISION_NAME}
    ))
    return (
      <div class="row">

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">New Department</h3>
            <p>Manage Departments According To Divisions</p>
          </div>
          <div class="card">
            <h5 class="card-header">Create Department</h5>
            <div class="card-body">
              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
              <Choose options={options} label="CHOOSE DIVISION" onchange={this.handleChange} ph="Select Division" />
              <Input onchange={this.textChange} name="department" ph="Enter Department Name" label="ENTER DEPARTMENT NAME" />
              <button onClick={this.save} className="btn btn-secondary"> Save Department</button>
            </div>
            <div class="card-body border-top">

            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title"> Departments </h3>
            <p>You Can View And Delete Departments Here</p>
          </div>
          <div class="card">
            <h5 class="card-header">List Of Departments <span class=" "></span></h5>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                {
                  departments.length < 1 ? <li class="list-group-item">No Divisions Available Add One On The Left Panel</li> : departments.map(data => (
                    <li class="list-group-item">{departments.indexOf(data)}) Department:{data.DEPARTMENT_NAME}, Division:{data.DISION_NAME}<button className="btn btn-danger btn-sm text-white float-right" onClick={
                      (e, deptId = data.DEPARTMENT_ID) =>
                      {
                        const postData = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: "Bearer "+localStorage.getItem("Token"),
                            deptId: deptId
                          })
                        }

                        Api("/departments/manage/delete", postData).then(data =>{
                          this.setState({
                            alert: "alert alert-danger",
                            message: data.message
                          })
                          this.InitFetch()
                        })
                      }
                    }><span className="fas fa-trash"></span></button></li>
                  ))
                }


              </ul>


            </div>
          </div>
        </div>
      </div>
    )
  }
}

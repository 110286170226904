import React, { Component } from 'react'
import { Api } from '../../../helper/Api';
import {Input, Choose} from '../../../components/Form'
export default class CompanyPayroll extends Component {
    constructor()
    {
        super()
        this.state ={
            sheet: [],
            totals: 0,
            month: "",
            year: ""
        }
        this.bootstrap = this.bootstrap.bind(this)
    }

    componentWillMount()
    {
        this.bootstrap()
    }
    bootstrap()
    {
        var data ={
            method: 'POST',
            body: JSON.stringify(
                {
                    token: "Bearer "+localStorage.getItem("Token")
                }
            )
        }

        Api('/payroll/manage/company', data).then(data => {
            this.setState({sheet: data.payrollData, gross: data.grossTotal})
        })
    }
   render()
   {
       const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

       var monthOpt = months.map(month => ({ value: month, label: month }))
       return(
           <div className="card">
               <h5 class="card-header">Company Overall Payroll</h5>

               < div className="col-md-6" >
                   <Choose options={monthOpt} label="Choose Month" onchange={
                       e => {
                           this.setState({
                               month: e.value
                           })

                           console.log(this.state);

                       }
                   } />
                   <Input label="Choose Year" ph="Year" onchange={
                       e => {
                           this.setState({
                               year: e.target.value
                           })
                       }
                   } />

                   <button className="btn btn-primary" onClick={
                       e => {
                           const postData = {
                               method: 'POST',
                               body: JSON.stringify({
                                   token: "Bearer " + localStorage.getItem('Token'),
                                   month: this.state.month,
                                   year: this.state.year
                               })
                           }
                           Api('/payroll/manage/company', postData).then(data => {
                               this.setState({ sheet: data.payrollData, gross: data.grossTotal })
                           })
                       }
                   }>Get Payroll</button>
               </div >
                
               <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
                <table className="table table-bordered table-hover">
                
                    <tr>
                            <th>EMPLOYEE ID</th>
                            <th>EMPLOYEE NAME</th>
                            <th>KRA PIN</th>
                            <th>NSSF NO</th>
                            <th>NHIF NO</th>
                            <th>BASIC SALARY</th>
                            <th>TOTAL DEDUCTIONS</th>
                            
                            <th>TOTAL ALLOWANCES</th>
                            <th>GROSS PAY</th>
                        
                    </tr>
                
                    <tbody>
                      {
                          this.state.sheet.map(data => (
                              <tr>
                                  <td>{data.id}</td>
                                  <td>{data.name}</td>
                                  <td>{data.kra}</td>
                                  <td>{data.nssf}</td>
                                  <td>{data.nhif}</td>
                                  <td>{data.basicSalary}</td>
                                  <td>{data.deduction}</td>
                                  
                                  <td>{data.allowance}</td>
                                  <td>{data.gross}</td>
                              </tr>
                          ))
                      }
                       <tr><td>TOTAL GROSS PAY</td><td>{this.state.gross}</td></tr>

                    </tbody>
                </table>
               </div>
           </div>
       )
       
   }
}

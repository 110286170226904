import React, { Component } from 'react'
import { getSessionPayload } from '../helper/Session'

export default class PageHeader extends Component {
  render() {
    return (
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="page-header">
                    <h2 class="pageheader-title h2"><i className="fas fa-dashboard"></i> {this.props.title} </h2>
                    <div class="page-breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href class="breadcrumb-link">{this.props.title}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{getSessionPayload().organisation}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

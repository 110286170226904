import React, {
    Component
} from 'react';
import Entry from './pages/Entry';
import "./App.css"
class App extends Component {
    render() {
        return ( <
            Entry / >
        );
    }
}

export default App;
import React, { Component } from 'react'
import { Input, Choose } from '../../../components/Form';
import { Api } from '../../../helper/Api';

export default class TaxRates extends Component {
   
  constructor()
  {
    super()
    this.state={
      alert: "",
      message: "",
      icon:"",
      taxes: [],
      from: "",
      to: "",
      rate: "",
      dType: "",
      rules:[]
    }

    this.save = this.save.bind(this)
  }
  componentWillMount()
  {
    this.init()
  }
  init()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }

    Api("/taxation/manage/list", data).then(data =>
      {
        this.setState({
          taxes: data
        })
      })
    Api("/taxation/manage/rules", data).then(data => {
      this.setState({
        rules: data
      })
    })
  }

  save()
  {
    const state = this.state
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token"),
        taxId: state.dType,
        from: state.from,
        to: state.to,
        rate: state.rate
      })
    }

    Api('/taxation/manage/rate', data).then(data => {
      this.setState({message: data.message, alert: "alert alert-success"});
      this.init()
    })
  }
  render() {
    const {taxes, rules} = this.state
    const opts = taxes.map(data => (
      {value: data.TAX_ID, label: data.TAX_NAME}
    ))
    return (
      <div class="row">

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">New Tax Rate</h3>
            <p>Create New Tax Rates</p>
          </div>
          <div class="card">
            <h5 class="card-header">Create Tax Rates</h5>
            <div class="card-body">
              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
              <Choose options={opts} onchange={(selected) => {
                this.setState({
                  dType: selected.value
                })
              }} label="CHOOSE TAXATION TYPE" />
              <Input onchange={(e) => {
                this.setState({
                  [e.target.name]: e.target.value
                })
              }} name="from" ph="Enter From" label="ENTER BASIC SALARY FROM" />
              <Input onchange={(e) => {
                this.setState({
                  [e.target.name]: e.target.value
                })
              }} name="to" ph="Enter To" label="ENTER BASIC SALARY TO" />
              <Input onchange={(e) => {
                this.setState({
                  [e.target.name]: e.target.value
                })
              }} name="rate" ph="Enter Taxation Rate" label="ENTER TAXATION RATE" />
              <button onClick={this.save} className="btn btn-secondary"> Save Rating</button>
            </div>
            <div class="card-body border-top">

            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title"> Taxations </h3>
            <p>You Can View And Delete Tax Rates Here</p>
          </div>
          <div class="card">
            <h5 class="card-header">List Of Taxation Rates <span class=""></span></h5>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                {
                  rules.length < 1 ? <li class="list-group-item">No Rates Available Add One On The Left Panel</li> : rules.map(data => (
                    <li class="list-group-item">{rules.indexOf(data)}) TAX TYPE: {data.TAX_NAME} FROM: {data.SAL_FROM} TO: {data.SAL_TO} RATE: {data.RATE} <button onClick={
                      (e, deleteId=data.RATE_ID) => {
                        const postData = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: "Bearer "+localStorage.getItem("Token"),
                            deleteId:deleteId
                          })
                        }

                        Api("/taxation/manage/deleterule", postData).then(data => {
                          this.init()
                        })
                      }
                    } className="btn btn-danger btn-sm text-white float-right"><span className="fas fa-trash"></span></button></li>
                  ))
                }


              </ul>


            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import {Input} from "../../../components/Form"
import {Api} from "../../../helper/Api"

export default class Add extends Component {
  constructor()
  {
    super()
    this.state = {
      division: "",
      divisions: [],
      alert: "",
      icon: "",
      message: ""
    }
    this.textChange = this.textChange.bind(this)
    this.save = this.save.bind(this)
    this.InitFetch = this.InitFetch.bind(this)
    }
  componentWillMount()
  {
    this.InitFetch()
  
  }
  
  InitFetch()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token") })
    }
    Api('/division/manage/list', data).then(data => {
      this.setState({divisions: data})
    })
  }
  textChange(e)
  {
    this.setState({[e.target.name]: e.target.value})
  }
  save()
  {
    
    const state = this.state
    if(state.division === "")
    {
      this.setState({alert: "alert alert-warning", icon:"fas fa-exclamation", message: "Fill In The Field"});
    } else{
      this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Saving..." });
      const data = {
        method: 'POST',
        body: JSON.stringify({token: "Bearer "+localStorage.getItem("Token"), division: state.division})
      }
      Api("/division/manage/add", data).then(data => {
        this.setState({alert: "alert alert-primary", icon:"fas fa-check", message: data.message})})
        this.InitFetch()
    }
  }
  render() {
    const {divisions} = this.state
    return (
      <div class="row">

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">New Division</h3>
            <p>If Your Company doesnt have any division, please enter the name of the company and exit</p>
          </div>
          <div class="card">
            <h5 class="card-header">Create Division</h5>
            <div class="card-body">
              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                  <Input onchange={this.textChange} name="division" ph="Enter Division Name" label="ENTER DIVISION NAME" />
                  <button onClick={this.save} className="btn btn-secondary"> Save Division</button>
                      </div>
                      <div class="card-body border-top">
                        
                            </div>
                          </div>
                            </div>
                            
          <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title"> Division </h3>
            <p>You Can View And Delete Divisons Here</p>
            </div>
                            <div class="card">
            <h5 class="card-header">List Of Division <span class=" "></span></h5>
              <div class="card-body">
                <ul class="list-group list-group-flush">
                {
                  divisions.length < 1 ? <li class="list-group-item">No Divisions Available Add One On The Left Panel</li> : divisions.map(data => (
                    <li class="list-group-item">{divisions.indexOf(data)}) {data.DISION_NAME} <button className="btn btn-danger btn-sm text-white float-right" onClick={
                      (e)=>{
                        const postData = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: `Bearer ${localStorage.getItem('Token')}`,
                            deleteId: data.DIVISION_ID
                          })
                        }
                        Api('/division/manage/delete', postData).then(data=>{
                          this.setState({
                            alert: "danger",
                            message: data.message
                          })
                        })

                        this.InitFetch()
                      }
                    }><span className="fas fa-trash"></span></button></li>
                  ))
                }
                  
                 
                </ul>
              
             
                            </div>
          </div>
                            </div>
                      </div>
    )
  }
}

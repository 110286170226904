import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import Departments from './Salary/Departments';
import PageHeader from '../../components/PageHeader';


export default class Salary extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="BASIC SALARY MODULE" />
                <Switch>
                    <Route path={this.props.match.path+"/departments"} component={Departments}  />    
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

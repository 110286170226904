import React, { Component } from 'react'
import { Input, Date as Dates, Choose } from '../../../components/Form'
import { Api } from '../../../helper/Api';
var gen = require('uniqid')
export default class EmployeeRegistration extends Component {
    constructor() {
        super()
        this.state = {
            departments: [],
            employeeId: "",
            department: "", 
            doe: new Date(),
            status: "",
            names: "",
            dob: new Date(),
            passport: "",
            contact: "",
            location:"",
            ac: "",
            alert: "",
            icon: "",
            message: "",
            KRA: "",
            NHIF: "",
            NSSF: ""
            
        }
        this.textChange = this.textChange.bind(this)
        this.save = this.save.bind(this)
        this.InitFetch = this.InitFetch.bind(this)
        this.documentsHandler = this.documentsHandler.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.photoChange = this.photoChange.bind(this)
    }
    componentWillMount() {
        this.InitFetch()

    }

    InitFetch() {
        const data = {
            method: 'POST',
            body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token") })
        }
        Api('/departments/manage/list', data).then(data => {
            this.setState({ departments: data })
        })
    }
    textChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    documentsHandler()
    {   
        const { fileContainers, fileCount} = this.state
        var newFile = fileCount + 1
        var element = fileContainers
        element.push(newFile)
        this.setState({fileContainers: element, fileCount: newFile})
    }
    save() {
        const  state = this.state
        var data = {
            method: 'POST',
            body: JSON.stringify({
                token: "Bearer "+localStorage.getItem("Token"),
                passport: state.passport,
                employeeId: state.employeeId,
                doe:state.doe,
                departmentId: state.department,
                status: state.status,
                names: state.names,
                dob: state.dob,
                contacts: state.contact,
                location: state.location,
                ac: state.ac,
                kra: state.KRA,
                nssf: state.NSSF,
                nhif: state.NHIF
            })
        }

        Api('/employees/manage/add', data).then(data => {
            this.setState({alert: "alert alert-primary", message: data.message, icon: "fas fa-check"})
            window.location.reload()
        })
    }
    photoChange(e)
    {
        const file = e.target.files
        
        const reader = new FileReader();

        reader.readAsDataURL(file[0])

        reader.onload = (e) => {
            console.log(e.target.result);
            var preview = document.getElementById('preview')
            preview.src = e.target.result

            this.setState({passport: e.target.result})

        }
        
        
    }

  
    handleSelect(selection)
    {
        this.setState({
            department: selection.value
        })
    }

    render() {
        const { departments, doe, dob,  employeeId } = this.state
        const options = departments.map(data => (
            {value: data.DEPARTMENT_ID, label: `DEPARTMENT:  ${data.DEPARTMENT_NAME} / DIVISION: ${data.DISION_NAME} `}
        ))
        return (
            <div class="row">
                <div class="col-md-3"></div> <div class="col-md-6"><div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                           </div>
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <h5 class="card-header">Work Details</h5>
                        <div class="card-body">
                             <Input value={employeeId} onchange={this.textChange} name="employeeId" ph="Enter Employee Id" label="ENTER EMPLOYEE ID" />
                           <button class="btn btn-success btn-xs" onClick={()=>{this.setState({employeeId: gen('employee-')})}}>Auto Generate</button>
                                            
                            <Dates label="DATE OF EMPLOYMENT" onchange={(date) => {
                                this.setState({
                                    doe: date
                                })}} value={doe} />
                            <Choose options={options} ph="Choose Departments" onchange={this.handleSelect} label="SELECT DEPARTMENT" />
                            <Input onchange={this.textChange} name="status" ph="Enter Employement Status" label="ENTER EMPLOYMENT STATUS" />
                            <Input onchange={this.textChange} name="ac" ph="Enter Account Number" label="ACCOUNT NUMBER (A/C no.)" />
                            <Input onchange={this.textChange} name="KRA" ph="Enter KRA PIN" label="KRA PIN" />
                            <Input onchange={this.textChange} name="NHIF" ph="NHIF" label="NHIF" />
                            <Input onchange={this.textChange} name="NSSF" ph="NSSF" label="NSSF" />

                        </div>
                        <div class="card-body border-top">

                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <h5 class="card-header">Personal Details</h5>
                        <div class="card-body">
                             <Input onchange={this.textChange} name="names" ph="Enter Full Names" label="FULL NAMES" />
                            <Dates label="DATE OF BIRTH" onchange={(date)=>{
                                this.setState({dob: date});
                            }} value={dob} />
                            <div class="form-group">
                            <Input onchange={this.photoChange} type="file" label="PASSPORT" />
                            </div>
                            <b>Passport Preview</b><br/>
                            <img src="" height="64" width="64" alt="Preview" id="preview" style={{borderRadius: "50%"}}/>
                            <Input onchange={this.textChange} name="contact" ph="Enter Contact Details" type="textarea" label="CONTACTS" />
                            <Input onchange={this.textChange} name="location" ph="Enter Address" label="ID NUMBER"/>

                        </div>
                        <div class="card-body border-top">

                        </div>
                    </div>
                </div>

                
                
                <div class="col-md-12">
                    <button onClick={this.save} className="btn btn-secondary"> Save Employee</button>
                </div>

            </div>
        )
    }
}

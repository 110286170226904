import React, { Component } from 'react'
import { Api, api } from '../../../helper/Api';

export default class Documents extends Component {
    constructor()
    {
        super()
        this.state = {
            filename: "",
            filesize: "",
            title: "",
            fileType: "",
            fileData: "",
            alert: "",
            message: "",
            documents: []
        }

        this.photoChange = this.photoChange.bind(this)
    }
    componentWillMount()
    {
        this.fetchData()
    }

    fetchData()
    {
        const postData = {
            method: 'POST',
            body: JSON.stringify({
                token: "Bearer "+localStorage.getItem("Token"),

            })
            
        }
        Api("/employees/manage/documents", postData).then(data => {
           this.setState({
           documents: data
           })
        })
    }

    photoChange(e)
  {
      const file = e.target.files
      const FileType = file[0]

      //descriptor
    console.log(FileType)

    this.setState({
        fileType: FileType.type,
        filesize: FileType.size/1000 + "kb",
        filename: FileType.name

    })
      

      if(FileType.type !== "application/x-php")
      {
        const reader = new FileReader();
        reader.readAsDataURL(file[0])
  
        reader.onload = (e) => {
            //console.log(e.target.result);
            
            this.setState({fileData: e.target.result})
        
        }
      } else {
        this.setState({
          alert: "alert alert-warning",
          message: "We Do Not Support This File Type"
        })
      }

      
      
  }

    
  render() {
      const {documents} = this.state
    return (
      <div className="row">
        
        <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div className={this.state.alert}><strong>{this.state.message}</strong></div>
        <div className="card-body">
            <div className="form-group">
                <label htmlFor="">File Title</label> <small>eg cv, resume, etc</small>
                <input type="text" name="title" onChange={(e)=>{this.setState({[e.target.name]: e.target.value})}} className="form-control" placeholder="File Title" />
                <label htmlFor="">Upload File</label> <small>upload any relevant file need here</small>
                <input type="file" className="form-control"  onChange={this.photoChange} placeholder="upload any relevant file need here" name="" id=""/>
<br/>
        <h5>File Descriptor</h5>
                <p>File: {this.state.filename} </p>
                <p>Size: {this.state.filesize} </p>
                <p>File Type: {this.state.fileType} </p>
        <br/>
                <button className="btn btn-info" onClick={(e)=>{
                    const state = this.state
                    const postData = {
                        method: 'POST',
                        body: JSON.stringify({
                            token: "Bearer "+localStorage.getItem("Token"),
                            title: state.title,
                            fileData: state.fileData,
                            filename: state.filename,

                        })
                        
                    }
                    Api("/employees/manage/document", postData).then(data => {
                       this.setState({
                        alert: "alert alert-info",
                        message: data.message
                       })
                    })
                }}> <i className="fas fa-upload"></i> Upload</button>
            </div>
        </div>
        </div>

        <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="card">
                                    <h5 class="card-header"> <i className="fas fa-folder-open"></i> My Uploads</h5>
                                    <div class="card-body p-0">
                                        <ul class="social-sales list-group list-group-flush">
                                            {
                                                documents.map(data => (
                                                    <li class="list-group-item social-sales-content"><span class="social-sales-icon-circle facebook-bgcolor mr-2"><i class="fas fa-file"></i></span><span class="social-sales-name"><a href={`${api}/uploads/${data.FILE_NAME}`}>{data.FILE_NAME}</a></span><span class="social-sales-count text-dark"><button className="btn btn-danger btn-xs" onClick={(e, filename=data.FILE_NAME, deleteId=data.FILE_ID)=>
                                                    {
                                                        const postData = {
                                                            method: 'POST',
                                                            body: JSON.stringify({
                                                                token: "Bearer "+localStorage.getItem("Token"),
                                                                deleteId: deleteId,
                                                                filename: filename
                                                            })
                                                        }
                                                        Api("/employees/manage/deletefiles", postData).then(data => {
                                                            this.setState({
                                                             alert: "alert alert-info",
                                                             message: data.message
                                                            })

                                                            this.fetchData()
                                                        })
                                                    }}>Trash File</button></span>
                                            </li>
                                                ))
                                            }
                                            
                                        </ul>
                                    </div>
                                    <div class="card-footer text-center">
                                        
                                    </div>
                                </div>
        </div>
      </div>
    )
  }
}

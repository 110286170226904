import React, { Component } from 'react'
import { Input, Choose } from '../../../components/Form';
import { Api } from '../../../helper/Api';

export default class Rates extends Component {

  constructor()
  {
    super()
    this.state={
      alert: "",
      message: "",
      icon:"",
      deduction: [],
      from: "",
      to: "",
      rate: "",
      dType: "",
      rules:[]
    }

    this.save = this.save.bind(this)
  }
  componentWillMount()
  {
    this.init()
  }
  init()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }

    Api("/deductions/manage/list", data).then(data =>
      {
        this.setState({
          deduction: data
        })
      })
    Api("/deductions/manage/rules", data).then(data => {
      this.setState({
        rules: data
      })
    })
  }

  save()
  {
    const state = this.state
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token"),
        deduction: state.dType,
        from: state.from,
        to: state.to,
        rate: state.rate
      })
    }

    Api('/deductions/manage/rate', data).then(data => {
      this.setState({message: data.message, alert: "alert alert-success"});
      this.init()
    })
  }
  render() {
    const {deduction, rules} = this.state
    const opts = deduction.map(data => (
      {value: data.DEDUCTION_ID, label: data.DEDUCTION_NAME}
    ))
    return (
      <div class="row">

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">New Deductions Rate</h3>
            <p>Create New Deductions Types</p>
          </div>
          <div class="card">
            <h5 class="card-header">Create Deductions</h5>
            <div class="card-body">
              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
              <Choose options={opts} onchange={(selected) => {
                this.setState({
                  dType: selected.value
                })
              }} label="CHOOSE DEDUCTION TYPE" />
              <Input onchange={(e) => {
                this.setState({
                  [e.target.name]: e.target.value
                })
              }} name="from" ph="Enter From" label="ENTER FROM" />
              <Input onchange={(e) => {
                this.setState({
                  [e.target.name]: e.target.value
                })
              }} name="to" ph="Enter To" label="ENTER TO" />
              <Input onchange={(e) => {
                this.setState({
                  [e.target.name]: e.target.value
                })
              }} name="rate" ph="Enter Deductions Rate" label="ENTER DEDUCTION RATE" />
              <button onClick={this.save} className="btn btn-secondary"> Save Deductions</button>
            </div>
            <div class="card-body border-top">

            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title"> Deductions </h3>
            <p>You Can View And Delete Deductions Here</p>
          </div>
          <div class="card">
            <h5 class="card-header">List Of Deductions <span class=" "></span></h5>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                {
                  rules.length < 1 ? <li class="list-group-item">No Deductions Available Add One On The Left Panel</li> : rules.map(data => (
                    <li class="list-group-item">{rules.indexOf(data)}) DEDUCTION: {data.DEDUCTION_NAME} FROM: {data.SAL_FROM} TO: {data.SAL_TO} RATE: {data.DEDUCTION} <button onClick={
                      (e, deleteId=data.ID) => {
                        const postData = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: "Bearer "+localStorage.getItem("Token"),
                            deleteId:deleteId
                          })
                        }

                        Api("/deductions/manage/deleterule", postData).then(data => {
                          this.init()
                        })
                      }
                    } className="btn btn-danger btn-sm text-white float-right"><span className="fas fa-trash"></span></button></li>
                  ))
                }


              </ul>


            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import {Api, api} from '../../../helper/Api'
import {Input, UpdateDate as Dates, Choose} from '../../../components/Form'
export default class Employees extends Component {
  constructor()
  {
    super()
    this.state = {
      employeeData: [],
      departments: [],
      employeeId: "",
      department: "",
      doe: "",
      status: "",
      names: "",
      dob: "",
      passport: "",
      contact: "",
      location: "",
      ac: "",
      files: [],
      fileCount: 0,
      fileContainers: [],
      alert: "",
      icon: "",
      message: "",
      updateColumn: "",
      updateTable:"",
      updateValue: "",
      updateId: "",
      empId: ""
      
    }
    this.Init = this.Init.bind(this)
    this.updateEmployee = this.updateEmployee.bind(this)
    this.updatePassport = this.updatePassport.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }
  componentWillMount()
  {
    this.Init()
  }
  
  Init()
  {
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }

    Api('/employees/manage/list', data).then(data => 
      {
        this.setState({employeeData: data});
      })
    Api('/departments/manage/list', data).then(data => {
      this.setState({ departments: data })
    })
  }

  updateEmployee(e)
  {
      var updateColumn = e.target.name
      var updateId = e.target.alt
      var updateTable = e.target.id
      var updateValue = e.target.value

      var postData = {
        method: 'POST',
        body: JSON.stringify({
          token: "Bearer " + localStorage.getItem("Token"),
          updateColumn: updateColumn,
          updateId: updateId,
          updateTable: updateTable,
          updateValue: updateValue
        })
      }

      Api('/employees/manage/edit', postData).then(data => {
        this.setState({ alert: "alert alert-success", message: data.message })
      })
  }

  handleSelect(e)
  {

  }

  updatePassport(e)
  {

    var files = e.target.files[0] === undefined ? {type: 'canceled'} : e.target.files[0];
    var empid = e.target.id
    console.log(files)
    var filetype  = files.type.split('/')[0]

    if(filetype === 'image')
    {
      const fs = new FileReader()

      fs.readAsDataURL(files)

      fs.onload = e => {
        var fileData = e.target.result
        var display = document.getElementById(`img-${empid}`)
        console.log(display)
        display.src = fileData

        const postData = {
          method: 'POST',
          body: JSON.stringify({
            token: `Bearer ${localStorage.getItem('Token')}`,
            updateColumn: 'PASSPORT',
            updateTable: 'EMPLOYEE_PERSONAL_INFO',
            updateId: empid,
            updateValue: `${empid}.png`,
            fileContent: fileData,
            file: true
          })
        }

        Api('/employees/manage/edit', postData).then(data => {
          this.setState({ alert: "alert alert-success", message: data.message })
        })
      }

      
    } else {
      this.setState({
        alert: 'danger',
        message: 'File Must Be An Image'
      })
    }

   

    /*var updateColumn = e.target.name
    var updateId = e.target.alt
    var updateTable = e.target.id
    var updateValue = e.target.value

    var postData = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer " + localStorage.getItem("Token"),
        updateColumn: updateColumn,
        updateId: updateId,
        updateTable: updateTable,
        updateValue: updateValue
      })
    }

    Api('/employees/manage/edit', postData).then(data => {
      this.setState({ alert: "alert alert-success", message: data.message })
    })*/
  }
  render() {
   
    const options =[{label:"ACTIVE", value:"ACTIVE"}, {label:"INACTIVE", value:"INACTIVE"}]
    
    return (
      <div>
            <h5 class="card-header">Employee Lists</h5>
       
        <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="section-block">
            <h5 class="section-title">Info</h5>
            <p>Click To Edit</p>
          </div>
          {
            this.state.employeeData.length < 1 ? <b>No Employees Avaliable Please Add Employees</b> : <span></span>
          }
          <div className={this.state.alert}>{this.state.message}</div>
          <div class="accrodion-regular">
            <div id="accordion3">
              {
                this.state.employeeData.map(data => (

                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target={"#"+data.EMPLOYEE_ID} aria-expanded="false" aria-controls="collapseSeven">
                            <button onClick={
                              (e, dob=data.DOB, doe=data.DOE) => {
                                this.setState({
                                  dob: dob,
                                  doe: doe,
                                 empId: data.EMPLOYEE_ID
                                })
                                
                              
                                
                              }
                            } className="btn btn-xs btn-info"> <span className="fas fa-edit"></span> edit</button>
                                               </button>
                        EMPLOYEE ID:{data.EMPLOYEE_ID} | EMPLOYEE NAME: {data.NAMES}  <div className="float-right"><button className="btn btn-xs btn-danger" onClick={()=>{
                          const postData = {
                            method: 'POST',
                            body: JSON.stringify({
                              token: "Bearer "+localStorage.getItem("Token"),
                              deleteId: data.EMPLOYEE_ID
                            })
                          }

                          Api("/employees/manage/delete", postData).then(data => {
                            this.setState({alert: "alert alert-success", message: data.message})
                            this.Init()
                          })
                          
                        }}> <span className="fas fa-trash"></span> delete</button></div>
                      </h5>

                     
                    </div>
                    <div id={data.EMPLOYEE_ID} class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3">
                      <div class="card-body row">
              
                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="card">
                            <h5 class="card-header">Work Details</h5>
                            <div class="card-body">
                              <Input ph={data.EMPLOYEE_ID} id="EMPLOYEES" alt={data.EMPLOYEE_ID} blur={this.updateEmployee} name="EMPLOYEE_ID"  label="ENTER EMPLOYEE ID" />
                              
                              <Choose options={options} id="EMPLOYEE_WORK_DETAIL" alt={data.EMPLOYEE_ID} ph="Choose Status" onchange={
                                (e)=>{
                                  this.setState({choosed: e})
                                  var updateColumn = "STATUS"
                                  var updateId =this.state.empId
                                  var updateTable = "EMPLOYEE_WORK_DETAIL"
                                  var updateValue = e.value

                                  var postData = {
                                    method: 'POST',
                                    body: JSON.stringify({
                                      token: "Bearer " + localStorage.getItem("Token"),
                                      updateColumn: updateColumn,
                                      updateId: updateId,
                                      updateTable: updateTable,
                                      updateValue: updateValue
                                    })
                                  }

                                  Api('/employees/manage/edit', postData).then(data => {
                                    this.setState({ alert: "alert alert-success", message: data.message })
                                  })
                                }
                              } label="SELECT EMPLOYEE STATUS" value={this.state.choosed} />
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="STATUS" id="EMPLOYEE_WORK_DETAIL" ph={data.STATUS} label="ENTER EMPLOYMENT STATUS" />
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="BILLING_ACCOUNTS" id="EMPLOYEE_BILLING"  ph={data.BILLING_ACCOUNTS} label="ACCOUNT NUMBER (A/C no.)" />
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="KRA" id="EMPLOYEE_BILLING"  ph={data.KRA} label="KRA" />
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="NSSF" id="EMPLOYEE_BILLING"  ph={data.NSSF} label="NSSF" />
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="NHIF" id="EMPLOYEE_BILLING"  ph={data.NHIF} label="NHIF" />

                            </div>
                            <div class="card-body border-top">

                            </div>
                          </div>
                        </div>

                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="card">
                            <h5 class="card-header">Personal Details</h5>
                            <div class="card-body">
                              <img height="200" width="200" src={`${api}/uploads/${data.PASSPORT}`} id={`img-${data.EMPLOYEE_ID}`} alt="PASSPORT" />

                              <Input id={data.EMPLOYEE_ID} type="file" name="passport" label="Change Passport Photo" onchange={this.updatePassport} />

                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="NAMES" id="EMPLOYEE_PERSONAL_INFO"  ph={data.NAMES} label="FULL NAMES" />

                              <div class="form-group">
                               {/* <Input onchange={this.photoChange} type="file" label="PASSPORT" />*/}
                              </div>
                              {/*<b>Passport Preview</b><br />
                              <img src="" height="64" width="64" alt="Preview" id="preview" style={{ borderRadius: "50%" }} />*/}
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="CONTACT" id="EMPLOYEE_PERSONAL_INFO"  type="textarea" ph={data.CONTACT} label="CONTACTS" />
                              <Input onchange={this.updateEmployee} alt={data.EMPLOYEE_ID} name="LOCATION" id="EMPLOYEE_PERSONAL_INFO"  ph={data.LOCATION} label="ID NUMBER" />
                              <Dates label="DATE OF BIRTH" onchange={(date, e) => {
                              //  var dates = `${date}`
                               this.setState({
                                dob:date
                               })
                                var updateColumn = "DOB"
                                var updateId = this.state.empId
                                var updateTable = "EMPLOYEE_PERSONAL_INFO"
                                console.log(e);
                                
                               var newdate = new Date(date.setDate(date.getDate() + 1))
                               date = new Date(date.setDate(date.getDate() - 1))
                                var postData = {
                                  method: 'POST',
                                  body: JSON.stringify({
                                    token: "Bearer " + localStorage.getItem("Token"),
                                    updateColumn: updateColumn,
                                    updateId: updateId,
                                    updateTable: updateTable,
                                    updateValue: newdate
                                  })
                                }

                                Api('/employees/manage/edit', postData).then(data => {
                                  this.setState({ alert: "alert alert-success", message: data.message })
                                })
                              
                              
                                
                              }} value={this.state.dob}  />
                              <Dates label="DATE OF EMPLOYMENT" onchange={(date, data) => {
                                //  var dates = `${date}`
                                this.setState({
                                  doe: date
                                })

                                var updateColumn = "DOE"
                                var updateId = this.state.empId
                                var updateTable = "EMPLOYEE_WORK_DETAIL"
                               

                                var newdate = new Date(date.setDate(date.getDate() + 1))
                                date = new Date(date.setDate(date.getDate() - 1))
                                var postData = {
                                  method: 'POST',
                                  body: JSON.stringify({
                                    token: "Bearer " + localStorage.getItem("Token"),
                                    updateColumn: updateColumn,
                                    updateId: updateId,
                                    updateTable: updateTable,
                                    updateValue: newdate
                                  })
                                }
                                Api('/employees/manage/edit', postData).then(data => {
                                  this.setState({ alert: "alert alert-success", message: data.message })
                                })

                              }} value={this.state.doe} click={
                                (e, empId = data.EMPLOYEE_ID) => {
                                  var updateColumn = "DOE"
                                  var updateId = empId
                                  var updateTable = "EMPLOYEE_WORK_DETAILS"
                                  var updateValue = this.state.doe
                                  
                                  var postData = {
                                    method: 'POST',
                                    body: JSON.stringify({
                                      token: "Bearer " + localStorage.getItem("Token"),
                                      updateColumn: updateColumn,
                                      updateId: updateId,
                                      updateTable: updateTable,
                                      updateValue: updateValue
                                    })
                                  }

                                  Api('/employees/manage/edit', postData).then(data => {
                                    this.setState({ alert: "alert alert-success", message: data.message })
                                  })
                                }
                              } />

                            </div>
                            <div class="card-body border-top">

                            </div>
                          </div>
                        </div>



                       {/* <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="card">
                            <h5 class="card-header">Documents</h5>
                            <div class="card-body">
                              <button className="btn btn-success" onClick={this.documentsHandler}>ADD FILE SLOT <span className="fas fa-plus-circle"></span></button> <hr />
                              {
                                fileContainers.map(data => (
                                  <div key={data} >
                                    <Input onchange={this.getFile} id={data} name="file" type="file" label="CHOOSE FILE" /> <hr />
                                  </div>
                                ))
                              }
                            </div>
                            <div class="card-body border-top">

                            </div>
                          </div>
                        </div>
                            */}

                      </div>
                    </div>
                  </div>
                ))
              }
                         </div>
          </div>
        </div>

      </div>
    )
  }
}

import React, { Component } from 'react'
import ReactToPrint from "react-to-print";
import { Api, api } from '../../../helper/Api';
import {Choose, Input} from '../../../components/Form'
class Report extends Component
{

  
  render()
  {
   

    return(
      <div>
        <div class="row">
                        <div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-header p-4">

                                {this.props.company.details.map(data => (
                                  <img height="80" width="80" src={`${api}/uploads/${data.LOGO}`} alt=""/>
                                ))}
                                    
                                    <div class="float-right">   <h3><a class="pt-2 d-inline-block" href="/" >{this.props.company.orgName}</a></h3>
                                   <h4 class="mb-0">PAYROLL</h4>
                                   <h4 class="mb-0">KRA PIN : {
                                      this.props.company.details.map(data => (
                                        <span>{data.KRA_PIN}</span>
                                      ))
                                    }</h4>
                                   {this.props.date}</div>
                                </div>
                                <div class="card-body">
                                   
                                    <div class="table-responsive-sm">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="center">EMP ID</th>
                                                    <th>EMPLOYEE NAME</th>
                                                    <th>ACCOUNT NUMBER</th>
                                                    <th class="center">BASIC SALARY</th>
                                                    <th class="center">TOTAL DEDUCTIONS</th>
                                                    <th class="center">TOTAL ALLOWANCES</th>
                                                    <th class="right">GROSS PAY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                          this.props.sheet.map(data => (
                              <tr>
                                  <td>{data.id}</td>
                                  <td>{data.name}</td>
                                  <td>{data.Ac}</td>
                                  <td>{data.basicSalary}</td>
                                  <td>
                                  {/*<table class="table table-striped">  <tr>{data.deductTbl.map(data =>(<td>{Object.keys(data).map(key=>(
                                    <tbody><td>{key} </td></tbody>
                                  ))}</td>))} <td>Total</td></tr> 
                                   <tr>{data.allowanceTbl.map(data =>(<td>{Object.keys(data).map(key=>(
                                    <tbody><td>{data[key]} </td></tbody>
                                  ))}</td>))} <td>{data.deduction}</td></tr> */}

                                  {data.deduction}
                                  
                                  
                                  </td>
                                
                                {/*
                                   <table class="table table-striped">  <tr>{data.allowanceTbl.map(data =>(<td>{Object.keys(data).map(key=>(
                                    <tbody><td>{key} </td></tbody>
                                  ))}</td>))} <td>Total</td></tr> 
                                   <tr>{data.allowanceTbl.map(data =>(<td>{Object.keys(data).map(key=>(
                                    <tbody><td>{data[key]} </td></tbody>
                                  ))}</td>))} <td>{data.allowance}</td></tr> 
                                  
                                  
                                   </table> */} <td>{data.allowance}</td> 
                                  
                                  <td>{data.gross}</td>
                              </tr>
                          ))
                      }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-sm-5">
                                        </div>
                                        <div class="col-lg-4 col-sm-5 ml-auto">
                                            <table class="table table-clear">
                                                <tbody>
                                                    <tr>
                                                        <td class="left">
                                                            <strong class="text-dark">TOTAL GROSS PAY</strong>
                                                        </td>
                                                        <td class="right">{this.props.gross}</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer bg-white">
                                    <p class="mb-0">{
                                      this.props.company.details.map(data => (
                                        <span>Location: {data.LOCATION} CONTACT: {data.CONTACT}</span>
                                      ))
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
      </div>
    )
  }
}
export default class Payrolls extends Component {

  constructor()
  {
    super()
    this.state = {
      company: {details: [], orgName:""},
      date: new Date(),
      sheet: [],
      gross: "",
      month: "",
      year: ""
    }

    this.mountFunction = this.mountFunction.bind(this)
  }

  componentWillMount()
  {
    this.mountFunction()
  }
  mountFunction()
  {
    //get organization Details
    const postData = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem('Token')
      })
    }
    Api("/workspace/manage/find", postData).then(
      data => {
        this.setState({
          company: data
        })
      }
    )


    //get Payroll Details
  }
  render() {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({ value: month, label: month }))
    
    return (
      <div>
        <h3>Overall Employees Payroll</h3>
        <ReactToPrint 
          trigger={() =>  <button className="btn btn-info">PRINT</button>}
          content={() => this.componentRef}
        />
        < div className="col-md-6" >
          <Choose options={monthOpt} label="Choose Month" onchange={
            e => {
              this.setState({
                month: e.value
              })

              console.log(this.state);

            }
          } />
          <Input label="Choose Year" ph="Year" onchange={
            e => {
              this.setState({
                year: e.target.value
              })
            }
          } />

          <button className="btn btn-primary" onClick={
            e => {
              const postData = {
                method: 'POST',
                body: JSON.stringify({
                  token: "Bearer " + localStorage.getItem('Token'),
                  month: this.state.month,
                  year: this.state.year
                })
              }
              Api('/payroll/manage/payroll', postData).then(data => {
                this.setState({sheet: [], gross: []})
                this.setState({ sheet: data.payrollData, gross: data.grossTotal })
              })
            }
          }>Get Payroll</button>
        </div >
       <Report date={this.state.date.toString()} sheet={this.state.sheet} gross={this.state.gross} company={this.state.company} ref={el => (this.componentRef = el)} />
                
      </div>
    )
  }
}

import React, { Component } from 'react'
import PageHeader from '../../components/PageHeader';
import { Api } from '../../helper/Api';

export default class Leave extends Component {
  constructor()
  {
    super()
    this.state = {
      leaves: []
    }
  }

  componentWillMount()
  {
    this.getChekpoints()
  }

  getChekpoints()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }
    Api('/leave/manage/list', data).then(
      data => {
        this.setState({
          leaves: data
        })        
      }
    )
  }
  render() {
    const {leaves} = this.state
    return (
      <div className="dashboard-wrapper bg-white">
       <div className="dashboard-ecommerce">
       <div className="container-fluid dashboard-content ">
           <PageHeader title="LEAVE APPROVAL" />
            <div class="row">
             
              <table className="table  table-stripped table-hover">
                <tr>

                  <th>EMPLOYEE NAME</th>
                  <th>POSTED TIME</th>
                  <th>DURATION</th>
                  <th>REASON</th>
                  <th>ACTION/STATUS</th>
                </tr>
                {leaves.length < 1 ? <tr><td>No Requests Here</td></tr> : console.log("Fetching..")}
                {
                  leaves.map(data => (
                    <tr key={data.REQUEST_ID}> 
                      <td>{data.EMPLOYEE_ID}</td>
                      <td>{(data.DATE_POSTED.split('gmt')[0])}</td>
                      <td> <strong>FROM:</strong> {data.LEAVE_FROM.split('T')[0]}, <strong>TO: </strong> {data.LEAVE_TO.split('T')[0]}</td>
                      <td>{data.LEAVE_MESSAGE}</td>
                      {data.LEAVE_STATUS === "PENDING" ? <td><button id={data.REQUEST_ID} className="btn btn-primary btn-xs" onClick={
                        (e) => {
                          const postData = {
                            method: 'POST',
                            body: JSON.stringify({
                              token: "Bearer "+localStorage.getItem("Token"),
                              status: "Approved",
                              requestId: e.currentTarget.id
                            })
                          }
                          Api('/leave/manage/update', postData).then(
                            data => {
                              this.getChekpoints()
                            }
                            )
                        }
                      }>Approve</button> <button id={data.REQUEST_ID} className="btn btn-xs btn-danger" onClick={
                        (e) => {
                          const postData = {
                            method: 'POST',
                            body: JSON.stringify({
                              token: "Bearer "+localStorage.getItem("Token"),
                              status: "Declined",
                              requestId: e.currentTarget.id
                            })
                          }

                          Api('/leave/manage/update', postData).then(
                            data => {
                              this.getChekpoints()
                            }
                            )
                        }
                      }>Decline</button></td> : <td><button className="btn btn-secondary btn-xs">{data.LEAVE_STATUS}</button></td>}
                       </tr>
                  ))
                }
              </table>
              
                            </div>
       </div>
       </div>
      </div>
    )
  }
}

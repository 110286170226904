import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import PageHeader from '../../components/PageHeader';
import CompanyPayroll from './Payroll/Company';
import IndividualPayroll from './Payroll/Individual';

export default class Payroll extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="PAYROLL MODULE" />
                <Switch>
                    <Route exact path={this.props.match.path+"/company"} component={CompanyPayroll}  />
                    <Route exact path={this.props.match.path+"/employee"} component={IndividualPayroll} />
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import PageHeader from '../../components/PageHeader';
import NewLoan from './Loans/NewLoan';
import Employee from './Loans/Employee';
export default class Loans extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="LOANS MODULE" />
                <Switch>
                    <Route path={this.props.match.path+"/types"} component={NewLoan}  />
                    <Route path={this.props.match.path+"/employee"} component={Employee}  />
                   
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import ReactToPrint from "react-to-print";
import { Api, api } from '../../../helper/Api';
class Report extends Component
{
  render()
  {
    return(
      <div>
        <div class="row">
                        <div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-header p-4">
                                {this.props.company.details.map(data => (
                                  <img height="80" width="80" src={`${api}/uploads/${data.LOGO}`} alt=""/>
                                ))}
                                    <div class="float-right"><h3><a class="pt-2 d-inline-block" href="/" >{this.props.company.orgName}</a></h3>
                                   <h4 class="mb-0">EMPLOYEE REPORT</h4>
                                   {this.props.date}</div>
                                </div>
                                <div class="card-body">
                                   
                                    <div class="table-responsive-sm">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="center">EMPLOYEE ID</th>
                                                    <th>EMPLOYEE NAME</th>
                                                    <th>DATE OF EMPLOYMENT</th>
                                                    <th>DATE OF BIRTH</th>
                                                    <th class="right">ID NUMBER</th>
                                                    <th class="center">CONTACT</th>
                                                    <th class="right">ACCOUNT NUMBER</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               {
                                                   this.props.tblData.map(data => (
                                                       <tr>
                                                           <td> {data.EMPLOYEE_ID} </td>
                                                           <td> {data.NAMES} </td>
                                                           <td> {data.DOE.split(":")[0].split("T")[0]} </td>
                                                           <td> {data.DOB.split(":")[0].split("T")[0]} </td>
                                                           <td> {data.LOCATION} </td>
                                                           <td> {data.CONTACT} </td>
                                                           <td> {data.BILLING_ACCOUNTS} </td>
                                                       </tr>
                                                   ))
                                               }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-sm-5">
                                        </div>
                                        <div class="col-lg-4 col-sm-5 ml-auto">
                                            <table class="table table-clear">
                                                <tbody>
                                                    <tr>
                                                        <td class="left">
                                                            <strong class="text-dark">TOTAL EMPLOYEES</strong>
                                                        </td>
                                                        <td class="right">{this.props.tblData.length}</td>
                                                    </tr>
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer bg-white">
                                <p class="mb-0">{
                                      this.props.company.details.map(data => (
                                        <span>Location: {data.LOCATION} CONTACT: {data.CONTACT}</span>
                                      ))
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
      </div>
    )
  }
}
export default class EmployeeReports extends Component {
    constructor()
    {
        super()
        this.state = {
            empData: [],
            company: {details: [], orgName:""},
            date: new Date()
        }

        this.fetchEmpData = this.fetchEmpData.bind(this)
    }
     
    componentWillMount()
    {
        this.fetchEmpData()
    }

    fetchEmpData()
    {
        const postData = {
            method: 'POST',
            body: JSON.stringify({
                token: "Bearer "+localStorage.getItem("Token")
            })
        }
       
       
        Api('/employees/manage/list', postData).then(data => {
           this.setState({
            empData: data
           })
        })
        Api("/workspace/manage/find", postData).then(
            data => {
              this.setState({
                company: data
              })
            }
          )

    }
  render() {
    return (
      <div>
         <ReactToPrint
          trigger={() =>  <button className="btn btn-info">PRINT</button>}
          content={() => this.componentRef}
        />
       
       <Report tblData = {this.state.empData} date={this.state.date.toString()} company={this.state.company}  ref={el => (this.componentRef = el)} />
                
      </div>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom';
import {modules } from './Routes';

export default class SideNavAuthed  extends React.Component {


  
  
  render()
  {
    return (
      <div class="nav-left-sidebar sidebar-dark">
        <div class="menu-list">
          <nav class="navbar navbar-expand-lg navbar-light">
            <ul class="navbar-nav flex-column">
              {
                modules.map(category => (
                 <span>
                    <li class="nav-divider">
                      {category.category}
                    </li>
                      {
                        category.modules.map(data => (
                          <li class= "nav-item">
                            <a  class="nav-link" href="#collapsable" data-toggle="collapse" aria-expanded="false" data-target={"#"+data.id} aria-controls="submenu-2"><i class={"fas fa-" + data.icon + " fa-fw "}></i> {data.name}</a>
                            <div id={data.id} class="collapse submenu  " > 
                              <ul class="nav flex-column">
                              {data.actions.map(action => (
                                <li class="nav-item">
                                  <Link class="nav-link" to={action.link}>{action.text}</Link>
                                </li>
                              ))}
                              </ul>
                            </div>
                          </li>
                        ))
                      }
                 </span>
                              
                ))
              }
  
              {
               /* modules.map(module =>(
                  <li class="nav-item">
                    <a class="nav-link" href data-toggle="collapse" aria-expanded="false" data-target="#submenu-2" aria-controls="submenu-2"><i class={"fas fa-"+module.icon + " fa-fw "}></i> {module.name}</a>
                    <div id="submenu-2" class="collapse submenu" >
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <a class="nav-link" href="pages/cards.html">Cards <span class="badge badge-secondary">New</span></a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="pages/general.html">General</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="pages/carousel.html">Carousel</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="pages/listgroup.html">List Group</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="pages/typography.html">Typography</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="pages/accordions.html">Accordions</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="pages/tabs.html">Tabs</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                ))*/
              }
              
                              </ul>
          </nav>
        </div>
  
      </div>      
  
      
    )
  }
}

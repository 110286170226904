import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import { empty } from '../../helper/validator';
import { Api } from '../../helper/Api';
class Register extends Component {
  constructor()
  {
    super()
    this.state = {
      company: "",
      username: "",
      password: "",
      password_valid: "",
      alert: "",
      icon: "",
      message: ""
    }
    this.changeText = this.changeText.bind(this)
    this.save = this.save.bind(this)
    
    
  }

  save(e)
  {

    e.preventDefault()
    console.log(this.state);
     
    if(empty(this.state.company)|| empty(this.state.username) || empty(this.state.password) || empty(this.state.password_valid))
    {
      //proceed
      this.setState({ alert: "alert alert-danger", icon: "fas fa-exclamation", message: "Please fill in all fields !" })

    }
    else
    {
      //proceed
      if(this.state.password !== this.state.password_valid)
      {
        this.setState({ alert: "alert alert-danger", icon: "fas fa-exclamation", message: "Passwords dont match !" })

      } else {
        this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Verifying and submiting information..." })
        const data = {
          method: 'POST',
          body: JSON.stringify({
            organization: this.state.company,
            username: this.state.username,
            password: this.state.password
          })
        }
        Api('/workspace/manage/register', data).then(data => {
          if(data.status === "success")
          {
            this.setState({ alert: "alert alert-primary", icon: "fas fa-check", message: data.message })
            window.location.href="/"
          }
          else
          {
            this.setState({ alert: "alert alert-danger", icon: "fas fa-exclamation", message: data.message })

          }
        })
      }
    }
  }
  changeText(e)
  {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
 render() {
   return (
     <div class="splash-container">
       <div class="card ">
         <div class="card-body">
           <div class="card-title text-center"><b>REGISTRATION</b></div>
           <form onSubmit={this.save}>
             <div className={this.state.alert}>
               <strong><i class={this.state.icon}></i></strong> <small>{this.state.message}</small>
             </div>
             <div className="form-group">
               <label htmlFor="">COMPANY</label>
               <input class="form-control form-control-lg" id="company" onChange={this.changeText} type="text" placeholder="Company Title" autocomplete="off" />
             </div>
             <div class="form-group">
               <label htmlFor="">USERNAME</label>
               <input class="form-control form-control-lg" id="username" onChange={this.changeText} type="text" placeholder="Username" autocomplete="off" />
             </div>
             <div class="form-group">
               <label htmlFor="">PASSWORD</label>
               <input class="form-control  form-control-lg" onChange={this.changeText} id="password" type="password" placeholder="Validate Password" />
             </div>
             <div class="form-group">
               <label htmlFor="">CONFIRM PASSWORD</label>
               <input class="form-control  form-control-lg" onChange={this.changeText} id="password_valid" type="password" placeholder="Password" />
             </div>

             <div class="text-center">
               <button type="submit" class="btn btn-primary btn-lg btn-bloc">SIGN UP</button></div>
           </form>
         </div>
         <div class="card-footer bg-white p-0text-center  ">
           <div class="card-footer-item card-footer-item-bordered">
             <Link to="/" class=" ">BACK TO AUTHENTICATION</Link></div>
         </div>
       </div>

     </div>
  )}
}

export default Register;

import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import Deduction from './Deductions/Add';
import PageHeader from '../../components/PageHeader';
import Rates from './Deductions/Rates';
import List from './Deductions/List';

export default class Deductions extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="DEDUCTION MODULE" />
                <Switch>
                    <Route path={this.props.match.path+"/types"} component={Deduction}  />
                    <Route  path={this.props.match.path+"/rates"} component={Rates} /> 
                    <Route  path={this.props.match.path+"/employee"} component={List} />    
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

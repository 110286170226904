import React, { Component } from 'react'
import PageHeader from '../../components/PageHeader';
import { Api, api } from '../../helper/Api';
import { getSessionPayload } from '../../helper/Session';

export default class Share extends Component {
    constructor()
    {
        super()
        this.state = {
            threads: [],
            message: "",
            filename: "",
            fileContent: "",
            response: "",
            alert:"",
            docs: []
        }

        this.fetchThreads = this.fetchThreads.bind(this)
        this.fileHandler = this.fileHandler.bind(this)

    }
    componentWillMount()
    {
        this.fetchThreads()
    }
    fetchThreads()
    {
        const postData = {
            method: 'POST',
            body: JSON.stringify({
                token: "Bearer "+localStorage.getItem("Token")
            })
        }
        
        Api('/workspace/share/threads', postData).then(data =>{
            this.setState({
                threads: data
            })
        })

        Api('/employees/manage/files', postData).then(data=>{this.setState({
            docs: data
        })})
    }
    fileHandler(e)
    {
        const file = e.target.files
        
        const reader = new FileReader();

        const filename = file[0].name

        this.setState({
            filename: filename
        })
    

        reader.readAsDataURL(file[0])

        reader.onload = (e) => {
            

            this.setState({fileContent: e.target.result})

        }
    }
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
      <div className="dashboard-ecommerce">
      <div className="dashboard-content">
      <PageHeader title="SHARE CENTER" />
        
            <div className="row">
                <div className="col-md-6" >
                    <div class="chat-module" >
                        <div class="chat-module-top">
                            
                            <div class="chat-module-body">
                            <div className={this.state.alert}>
                                <strong>{this.state.response}</strong>
                            </div>
                                {this.state.threads.length < 1 ? <div class="media chat-item">
                                    <h6>No Threads Yet..But You Can Add A New Thread Below.</h6>
                                </div> : <div></div>}

                                {
                                    this.state.threads.map(thread => (
                                        <div class="media chat-item">
                                    <img alt="William" src={`${api}/uploads/${thread.AVATAR}`} class="rounded-circle user-avatar-lg" />
                                    <div class="media-body">
                                        <div class="chat-item-title">
                                            <span class="chat-item-author">{thread.USERNAME}</span>
                                            <span>{thread.TIME}</span>
                                            {getSessionPayload().rank === 'Administrator' ? <button onClick={
                                                (e, deleteId=thread.THREAD_ID) =>{
                                                    const postData = {
                                                        method: 'POST',
                                                        body: JSON.stringify({
                                                            token: "Bearer "+localStorage.getItem("Token"),
                                                            deleteId: deleteId
                                                        })
                                                    }

                                                    Api('/workspace/share/delete', postData).then(data => {
                                                        this.setState({
                                                            alert : "alert alert-danger",
                                                            response: data.message
                                                        })
                                                        this.fetchThreads()
                                                    })
                                                }
                                            } className="btn btn-rounded btn-danger btn-xs"><i className="fa fa-trash"></i></button> : <span></span>}
                                        </div>
                                        <div class="chat-item-body">
                                            <p>{thread.MESSAGE}</p>
                                           {thread.ATTACHMENT === "" ? <span> </span> :  <div class="media media-attachment">
                                            <div class="avatar bg-primary">
                                                <i class="fas fa-file"></i>
                                            </div>
                                            <div class="media-body">
                                                <a href={`${api}/uploads/${thread.ATTACHMENT}`} class="">{thread.ATTACHMENT}</a>
                                                
                                            </div>
                                        </div>}
                                        </div>
                                    </div>
                                </div>
                                    ))
                                }
                               
                            </div>
                        </div>
                        <div class="chat-module-bottom">
                            <form onSubmit={
                                (e)=>{
                                    e.preventDefault()
                                    this.setState({
                                        alert: "alert alert-info",
                                        response: "Adding Thread"
                                    })
                                    var body = {
                                        token: "Bearer "+localStorage.getItem("Token"),
                                        message: this.state.message,
                                        filename: this.state.filename,
                                        attachment: this.state.fileContent
                                    }
                                    
                                    const postDate = {
                                        method: 'POST',
                                        body:JSON.stringify(body)
                                    }

                                    Api("/workspace/share/new", postDate).then(data => {
                                        this.setState({
                                            alert: "alert alert-primary",
                                            response: data.message,message:"",attachment:""
                                        })
                                        this.fetchThreads()
                                    })
                                }
                            } class="chat-form">
                                <textarea value={this.state.message} class="form-control" placeholder="Type message" rows="1" onChange={
                                    (e)=>
                                    {
                                        this.setState({
                                            message: e.target.value,
                                        })
                                        
                                    }
                                }></textarea>
                                <div class="chat-form-buttons">
                                    <button type="button" class="btn btn-link">
                                        <i class="far fa-smile"></i>
                                    </button>
                                    <div class="custom-file custom-file-naked">
                                        <input value={this.state.attachment} onChange={this.fileHandler} type="file" class="custom-file-input" id="customFile" />
                                        <label class="custom-file-label" for="customFile">
                                            <i class="fas fa-paperclip"></i>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-info" type="submit"> <i className=" fas fa-share-alt"></i> Share Thread</button>
                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-title">File Uploads</div>
                            <table className="table table-stripped">
                                <tr>
                                    <th>UPLOADER</th>
                                    
                                    <th>FILE ADDRESS</th>
                                </tr>

                                

                                    {this.state.docs.length < 1 ? <tr><td colSpan={12}>No Uploads Yet</td></tr> : this.state.docs.map(data => (
                                        <tr>
                                            <td>{data.NAMES}</td>
                                            <td><a href={`${api}/uploads/${data.FILE_NAME}`}>{data.FILE_NAME}</a></td>
                                        </tr>
                                    )) }

                            </table>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
               
      </div>
    )
  }
}

import React, { Component } from 'react'

import { Api } from '../../../helper/Api'
import { Input, Choose } from '../../../components/Form'


export default class List extends Component {
  constructor() {
    super()
    this.state = {
      employeeData: [],
      departments: [],
      deductionTypes: [],
      empId: "",
      deduction: "",
      amount: "",
      alert: "",
      icon: "",
      message: "",
      empData: [],
      month: "",
      year: ""
    }
    this.Init = this.Init.bind(this)
    this.save = this.save.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.textChange = this.textChange.bind(this)
  }
  componentWillMount() {
    this.Init()
  }

  Init() {
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer " + localStorage.getItem("Token")
      })
    }

    Api('/employees/manage/list', data).then(data => {
      this.setState({ employeeData: data });
    })
    Api('/deductions/manage/list', data).then(data => {
      this.setState({ deductionTypes: data })
    })
  }


  save() {
    const state = this.state
    const data = {
      method: 'POST',
      body: JSON.stringify(
        {
          token: "Bearer " + localStorage.getItem("Token"),
          amount: state.amount,
          deduction: state.deduction,
          empId: state.empId,
          month: this.state.month,
          year: this.state.year
        }
      )
    }

    Api("/deductions/manage/other", data).then(data => {
      this.setState({
        alert: "alert alert-primary",
        message: data.message
      })
      var fetchData = {
        method: 'POST',
        body: JSON.stringify({
          token: "Bearer " + localStorage.getItem("Token"),
          empId: this.state.empId,
          month: this.state.month,
          year: this.state.year
        })
      }

      Api('/deductions/manage/one', fetchData).then(data => { this.setState({ empData: data }) })
    })
  }

  handleChange(e) {
    this.setState({
      deduction: e.value
    })
  }
  textChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const { empData } = this.state
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({ value: month, label: month }))


    return (
      <div>

        <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="section-block">
            <h5 class="section-title">Info</h5>
            <p>Manage Employee DEDUCTIONS</p>
          </div>
          {
            this.state.employeeData.length < 1 ? <b>No Employees Avaliable Please Add Employees</b> : <span></span>
          }
          <div className={this.state.alert}>{this.state.message}</div>
          <div class="accrodion-regular">
            <div id="accordion3">
              {
                this.state.employeeData.map(data => (
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" name={data.EMPLOYEE_ID} onClick={(e) => {
                          this.setState({ empId: data.EMPLOYEE_ID })
                          
                        }} data-target={"#" + data.EMPLOYEE_ID} aria-expanded="false" aria-controls="collapseSeven">
                          <button className="btn btn-xs btn-info"> SET DEDUCTIONS</button>
                        </button>
                        EMPLOYEE ID:{data.EMPLOYEE_ID} | EMPLOYEE NAME: {data.NAMES}
                      </h5>


                    </div>
                    <div id={data.EMPLOYEE_ID} class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3">
                     <div className="col-md-6">
                        <Choose options={monthOpt} label="Choose Month" onchange={
                          e => {
                            this.setState({
                              month: e.value
                            })

                            console.log(this.state);

                          }
                        } />
                        <Input label="Choose Year" ph="Year" onchange={
                          e => {
                            this.setState({
                              year: e.target.value
                            })
                          }
                        } />

                        <button className="btn btn-primary" onClick={
                          e=>{
                            var fetchData = {
                              method: 'POST',
                              body: JSON.stringify({
                                token: "Bearer " + localStorage.getItem("Token"),
                                empId: data.EMPLOYEE_ID,
                                month: this.state.month,
                                year: this.state.year
                              })
                            }

                            Api('/deductions/manage/one', fetchData).then(data => { this.setState({ empData: data }) })
                          }
                        }>Generate Deduction List</button>
                     </div>
                      <div class="row">

                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="section-block" id="basicform">
                            <h3 class="section-title">New DEDUCTIONS</h3>
                            <p>Manage DEDUCTIONS</p>
                          </div>
                          <div class="card">
                            <h5 class="card-header">Set Deductions</h5>
                            <div class="card-body">
                              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                              <Input onchange={this.textChange} label="CHOOSE Deduction  TYPE" ph="Select DEDUCTIONS TYPE" name="deduction" />
                              <Input onchange={this.textChange} name="amount" ph="Enter AMOUNT" type="number" />
                              <button onClick={this.save} className="btn btn-secondary"> Save</button>
                            </div>
                            <div class="card-body border-top">

                            </div>
                          </div>
                        </div>

                        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class="section-block" id="basicform">
                            <h3 class="section-title">List  of DEDUCTIONS For {data.EMPLOYEE_ID} </h3>

                          </div>
                          <div class="card">
                            <h5 class="card-header">List Of Deductions <span class=" "></span></h5>
                            <div class="card-body">
                              <ul class="list-group list-group-flush">
                                {
                                  empData.length < 1 ? <li class="list-group-item">No Deductions Available Add One On The Left Panel</li> : empData.map(data => (
                                    <li class="list-group-item">{empData.indexOf(data)}) DEDUCTIONS:{data.DEDUCTION_NAME}, AMOUNT:{data.AMOUNT}<button onClick={
                                      (e, del = data.ID) => {
                                        const postData = {
                                          method: 'POST',
                                          body: JSON.stringify({
                                            token: "Bearer " + localStorage.getItem("Token"),
                                            deleteId: del
                                          })
                                        }

                                        Api('/deductions/manage/deleteemp', postData).then(data => {
                                          this.setState({
                                            alert: "alert alert-danger",
                                            message: data.message
                                          })
                                          var fetchData = {
                                            method: 'POST',
                                            body: JSON.stringify({
                                              token: "Bearer " + localStorage.getItem("Token"),
                                              empId: this.state.empId,
                                              month: this.state.month,
                                              year: this.state.year
                                            })
                                          }

                                          Api('/deductions/manage/one', fetchData).then(data => { this.setState({ empData: data }) })
                                        })
                                      }
                                    } className="btn btn-danger btn-sm text-white float-right"><span className="fas fa-trash"></span></button></li>
                                  ))
                                }


                              </ul>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

      </div>
    )
  }
}

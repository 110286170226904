import React from 'react'
import SideNavAuthed from './Sidenav/SideNavAuthed';
import { loggedIn, getSessionPayload } from '../helper/Session';


const Auth = loggedIn();

console.log(getSessionPayload());//debig

export default function Sidenav(props) {
    if(Auth)
    {

       // const links = getSessionPayload().access.Access === "Administrator" ? linsks : generateNav(getSessionPayload().PRIVILAGE);
       return <SideNavAuthed/>
    } else {
       return  <div>

        </div>
    }
}

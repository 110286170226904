import React from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";

function Input(props) {
  return (
    <div className="form-group">
    <label htmlFor="">{props.label}</label>
      <input alt={props.alt} className="form-control" onBlur={props.blur} value={props.value} onChange={props.onchange} id={props.id} type={props.type} name={props.name} placeholder={props.ph} />
    </div>
  )
}

function Date(props) {
  //remove the css Tags

  const el = document.getElementsByClassName('react-datepicker__input-container')
  if(el.length >= 1)
  {
    el[0].classList.remove('react-datepicker__input-container')
  }

  const el2 = document.getElementsByClassName('react-datepicker-wrapper')
  if (el2.length >= 1) {
    el2[0].classList.remove('react-datepicker-wrapper')
  }
  
 
  

  return(
    <div className="form-group">
    <label htmlFor="">{props.label}</label> <br/>
      <DatePicker name={props.name} alt={props.alt} placeholder={props.ph} selected={props.value} onChange={props.onchange} className="form-control" />
    </div>
  )
}

function UpdateDate(props) {
  //remove the css Tags

  const el = document.getElementsByClassName('react-datepicker__input-container')
  if (el.length >= 1) {
    el[0].classList.remove('react-datepicker__input-container')
  }

  const el2 = document.getElementsByClassName('react-datepicker-wrapper')
  if (el2.length >= 1) {
    el2[0].classList.remove('react-datepicker-wrapper')
  }




  return (
    <div className="form-group">
      <label htmlFor="">{props.label}</label> <br />
      <DatePicker name={props.name} alt={props.alt} placeholder={props.ph} selected={props.value} onChange={props.onchange} className="form-control" /> 
  
    </div>
  )
}

function Choose(props) {
 return(
    <div className="form-group">
      <label htmlFor="">{props.label}</label>
      <Select options={props.options} onChange={props.onchange} value={props.value} placeholder={props.php} />
    </div>
 )
}

export {Input, Date, Choose, UpdateDate}

import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import PageHeader from '../../components/PageHeader';
import Payslip from './Reports/Payslips';
import EmployeeReports from './Reports/EmployeeReport';
import Payrolls from './Reports/Payroll';
import deductions from './Reports/deductions';
import allowances from './Reports/allowances';

export default class Reports extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="REPORTS MODULE" />
                <Switch>
                    <Route path={this.props.match.path+"/payslip"} component={Payslip}  />
                    <Route path={this.props.match.path+"/employees"} component={EmployeeReports}  />
                    <Route path={this.props.match.path+"/payroll"} component={Payrolls}  />
              <Route path={this.props.match.path + "/deductions"} component={deductions} />
              <Route path={this.props.match.path + "/allowances"} component={allowances} />
                    
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

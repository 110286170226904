import React, { Component } from 'react'
import PageHeader from '../../components/PageHeader';
import { getSessionPayload } from '../../helper/Session';
import { api, Api } from '../../helper/Api';

export default class Account extends Component {
	constructor()
	{
		super()
		this.state = {
			username: "",
			password: "",
			avatar: "",
			alert: "",
			message: ""
		}

		this.getUser = this.getUser.bind(this)
		this.updateField = this.updateField.bind(this)
		this.changeAvatar = this.changeAvatar.bind(this)
	}
	componentWillMount()
	{
		this.getUser()
	}
	updateField(e)
	{
		const postDat = {
			method: 'POST',
			body: JSON.stringify({
				token: "Bearer "+localStorage.getItem("Token"),
				updateColumn: e.target.name,
				updateValue: e.target.value
			})
			
		}

		if(e.target.value !== "")
		{
			Api("/auth/account/editaccount", postDat).then(data => {
				this.setState({
					alert: "alert alert-info",
					message: data.message
				})
			})
		} else {
			this.setState({
				alert: "alert alert-danger",
				message: "You Cannot Leave It Empty"
			})
		}
	}
	getUser()
	{
		const postData = {
			method: 'POST',
			body:JSON.stringify({
				token: "Bearer "+localStorage.getItem("Token")
			})
		}

		Api('/auth/account/find', postData).then(data=>{
			this.setState({
				username: data.username,
			})
		})
	}
	changeAvatar(e)
	{
		const file = e.target.files

		const reader = new FileReader();

		reader.readAsDataURL(file[0])

		reader.onload = (e) => {
			
			var preview = document.getElementById('preview')
			preview.src = e.target.result

			//upload Image To Server

			const postData = {
				method: 'POST',
				body: JSON.stringify({
					fileContents: e.target.result,
					token: "Bearer "+localStorage.getItem("Token")
				})
			}

			Api('/auth/account/avatar', postData).then(data => { this.setState({
				alert: "alert alert-primary",
				message: data.message
			})})
		}
		
	}
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="My Account" />
                <h3>Hello {getSessionPayload().username}, You Can Manage Your Account Here</h3>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
	                        <div class="card influencer-profile-data">
	                            <div class="card-body">
	                                <div class="row">
	                                    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12">
	                                        <div class="text-center">
	                                            <img src={`${api}/uploads/${getSessionPayload().avatar}`} id="preview" alt="User Avatar" class="rounded-circle user-avatar-xxl"/>
	                                            </div>
	                                        </div>
	                                        <div class="col-xl-10 col-lg-8 col-md-8 col-sm-8 col-12">
	                                            <div class="user-avatar-info">
	                                                <div class="m-b-20">
	                                                    <div class="user-avatar-name">
	                                                        <h2 class="mb-1">{getSessionPayload().username}</h2>
	                                                    </div>

	                                                </div>
	                                                  <div class="float-right"><a href="#sl" class="user-avatar-email text-secondary">{getSessionPayload().userId}</a></div> -->
	                                                <div class="user-avatar-address">
	                                                    <p class="border-bottom pb-3">
	                                                       
	                                                    </p>
	                                                    <div class="mt-3">
	                                                        <a href="#sv" class="badge badge-light mr-1">Your Account Level Is {getSessionPayload().rank}</a> 
	                                                    </div>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                                <div class="border-top user-social-box">
	                                                 </div>
	                            </div>
	                        </div>

							 <hr/>
							 <h5>Manage Accounts Here</h5>
							 <div className={this.state.alert}>
							 	<strong>{this.state.message}</strong>
							 </div>

							 <div className="form-group col-md-6">
								<label htmlFor="">Change Profile Photo</label>
								<input type="file" onChange={this.changeAvatar} className="form-control" />
								<label htmlFor="">Enter Username</label>
								<input type="text" placeholder="update username" name="USERNAME" onBlur={this.updateField}  className="form-control"/>
								<label htmlFor="">Change Password</label>
								<input type="text"  onBlur={this.updateField} name="PASSWORD" placeholder="Enter Password"  className="form-control"/>
								<h6>Saves Automatically. Some of the changes will be applied in system the next time you log in</h6>
							 </div>
            </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import PageHeader from '../../components/PageHeader';
import Access from './Settings/Access';
import Workspace from './Settings/Workspace';

export default class Settings extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="SETTINGS MODULE" />
                <Switch>
                    <Route path={this.props.match.path+"/access"} component={Access}  />
                    <Route exact path={this.props.match.path+"/workspace"} component={Workspace}  />
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import PageHeader from '../../components/PageHeader';
import Payslip from './Reports/Payslip';
import Leaves from './selfs/Leaves';
import Documents from './selfs/Documents';
import P9Forms from './selfs/P9Forms';



export default class Self extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="EMPLOYEE PANEL" />
                <Switch>
                    <Route exact path={this.props.match.path+"/payslip"} component={Payslip}  />
                    <Route  path={this.props.match.path+"/leave"} component={Leaves}  />
                    <Route  path={this.props.match.path+"/documents"} component={Documents}  />
                    <Route  path={this.props.match.path+"/p9_forms"} component={P9Forms}  />
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}

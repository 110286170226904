import React, { Component } from 'react'
import { Api } from '../../../helper/Api';
import { Input } from '../../../components/Form';

export default class TaxTypeManagement extends Component {
    constructor(){
        super()
        this.state = {
            taxType : "",
            taxTypes: [],
            status: []
        }
    }
    componentWillMount(){
      Api('/taxation/manage/list', {
        method: 'POST',
        body: JSON.stringify({
          token: "Bearer " + localStorage.getItem('Token'),
          taxName: this.state.taxType
        })
      }).then(data =>{
        this.setState({ taxTypes: data})
      })
    }
    render() {
        return (
            <div>
                <h3 class="section-title h5">MANAGE TAXATION TYPES / BODY(IES)</h3>
            <p>Manage Taxation modes</p>
            <div class="card">
            
           <div className="row">
           <div class="card-body">
              
              <div className="col-md-6">
              <Input label="Enter Taxation Type / Body(ies)" ph="Taxation Type" onchange={
                e => {
                  this.setState({
                    taxType: e.target.value
                  })
                }
              } />

              <button className="btn btn-primary" onClick={
                e => {
                  const postData = {
                    method: 'POST',
                    body: JSON.stringify({
                      token: "Bearer " + localStorage.getItem('Token'),
                      taxName: this.state.taxType
                    })
                  }
                  Api('/taxation/manage/add', postData).then(data => {
                    this.setState({ status: data, taxTypes: [] })
                    Api('/taxation/manage/list', {
                      method: 'POST',
                      body: JSON.stringify({
                        token: "Bearer " + localStorage.getItem('Token'),
                        taxName: this.state.taxType
                      })
                    }).then(data =>{
                      this.setState({ taxTypes: data})
                    })
                  })
                }
              }>ADD TAX TYPE</button>
              </div>
              <div>
                  <table className="table table-bordered table-hover table-editable">
                      <tr>
                          <th>TAXATION TYPE</th>
                          <th>ACTIONS</th>
                      </tr>
                      <tbody>
                        {
                          this.state.taxTypes.map(data =>  (
                            <tr>
                              <td
                              id={data.TAX_ID} contentEditable onBlur={
                                (e)=>{
                                  var postData ={
                                    method: 'POST',
                                    body: JSON.stringify(
                                      {
                                        token: "Bearer "+localStorage.getItem("Token"),
                                        taxId: e.target.id,
                                        taxName: e.currentTarget.textContent
                                      }
                                    )
                                  }
        
                                  Api('/taxation/manage/edit', postData).then(data=>{console.log(data);
                                  })
                                }
                              }
                              >{data.TAX_NAME}</td>
                              <td>
                                <button id={data.TAX_ID} className="btn btn-danger btn-xs" onClick={
                                  e =>{
                                    var postData ={
                                      method: 'POST',
                                      body: JSON.stringify(
                                        {
                                          token: "Bearer "+localStorage.getItem("Token"),
                                          taxId: e.target.id,
                                          
                                        }
                                      )
                                    }
          
                                    Api('/taxation/manage/delete', postData).then(data=>{
                                      Api('/taxation/manage/list', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                          token: "Bearer " + localStorage.getItem('Token'),
                                          taxName: this.state.taxType
                                        })
                                      }).then(data =>{
                                        this.setState({ taxTypes: data})
                                      })
                                    });
                                    
                                  }
                                }>Delete</button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                  </table>
              </div>
            </div >
           </div>
            </div>
            </div>  
            
        )
    }
}

import React, { Component } from 'react'
import { Api } from '../../../helper/Api';
import { Date as Dates } from '../../../components/Form';
const randoms = require('uniqid')
export default class Leaves extends Component {
  constructor()
  {
    super()
    this.state = {
      ref: "",
      reason: "",
      leaves: [],
      from: new Date() ,
      to:  new Date()
    }
    this.handleChange = this.handleChange.bind(this)
    this.getLeaves = this.getLeaves.bind(this)
  }
  componentWillMount()
  {
    this.getLeaves()
  }

  getLeaves()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }
    Api('/leave/manage/individual', data).then(
      data => {
        this.setState({
          leaves: data
        })        
      }
    )
  }

  handleChange(e)
  {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const {leaves} = this.state
    return (
      <div>
          <h3>Request Leave</h3>
        <div className="form-group col-md-8">
          
          <Dates label="Leave Start Date" ph="start date" name="from" value={this.state.from} onchange={(date) => {
            this.setState({
              from: date
            })
          }} />
          <Dates label="Leave End Day" ph="start date" name="to" value={this.state.to} onchange={(date)=>{
            this.setState({
              to: date
            })
          }} />
            <label htmlFor="">LEAVE MESSAGE</label>
            <textarea rows="10" placeholder="Leave Reason" type="textarea" className="form-control" name="reason" onChange={this.handleChange} id=""/>

            <br/>
            <button className="btn btn-success" onClick={(e)=>{
              const postData = {
                method: 'POST',
                body: JSON.stringify({
                  token: "Bearer "+localStorage.getItem("Token"),
                  ref: "REF_" + randoms() ,
                  from: this.state.from,
                  to: this.state.to,
                  dp: Date().toLocaleLowerCase(),
                  reason: this.state.reason
                })
              }

              Api('/leave/manage/add', postData).then(data => {
                this.getLeaves()
              })
            }}>SAVE</button>
        </div>

        <table className="table  table-stripped table-hover">
                <tr>
                 
                  <th>REF</th>
                  <th>POSTED TIME</th>
                  <th>REASON</th>
                  <th>DURATION</th>
                  <th>ACTION</th>
                  <th>STATUS</th>
                </tr>
                {leaves.length < 1 ? <tr><td>No Requests Here</td></tr> : console.log("Fetching..")}
                {
                  leaves.map(data => (
                    <tr key={data.REQUEST_ID}> 
                     
                      <td>{data.LEAVE_REF}</td>
                      <td>{(data.DATE_POSTED.split('gmt')[0])}</td>
                      <td>{data.LEAVE_MESSAGE}</td>
                      <td>FROM : {data.LEAVE_FROM.split('T')[0]}, TO: {data.LEAVE_TO.split('T')[0]}</td>
                     <td> {data.LEAVE_STATUS }</td>
                     <td><button className="btn btn-danger btn-sm" id={data.REQUEST_ID} onClick={(e)=>{
                       const postData = {
                         method: 'POST',
                         body:JSON.stringify({
                           token: "Bearer "+localStorage.getItem("Token"),
                           deleteId: e.currentTarget.id
                         })
                       }

                       Api('/leave/manage/delete', postData).then(
                        data => {
                          this.getLeaves()       
                        }
                      )

                       
                     }}>Delete</button></td>
                       </tr>
                  ))
                }
              </table>
      </div>
    )
  }
}

import React, { Component } from 'react'
import {Input, Choose} from "../../../components/Form"
import {Api} from "../../../helper/Api"

export default class Add extends Component {
  constructor()
  {
    super()
    this.state = {
      allowance: "",
      allowances: [],
      alert: "",
      icon: "",
      message: "",
      mode: ""
    }
    this.textChange = this.textChange.bind(this)
    this.save = this.save.bind(this)
    this.InitFetch = this.InitFetch.bind(this)
    this.selected = this.selected.bind(this)
    }
  componentWillMount()
  {
    this.InitFetch()
  
  }
  
  InitFetch()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token") })
    }
    Api('/allowance/manage/list', data).then(data => {
      this.setState({allowances: data})
    })
  }
  textChange(e)
  {
    this.setState({[e.target.name]: e.target.value})
  }
  save()
  {
    
    const state = this.state
    if(state.allowance === "" && state.mode === "")
    {
      this.setState({alert: "alert alert-warning", icon:"fas fa-exclamation", message: "Fill In The Field"});
    } else{
      this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Saving..." });
      const data = {
        method: 'POST',
        body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token"), allowance: state.allowance, mode: state.mode})
      }
      Api("/allowance/manage/add", data).then(data => {
        this.setState({alert: "alert alert-primary", icon:"fas fa-check", message: data.message})})
        this.InitFetch()
    }
  }

  selected(options)
  {
    this.setState({mode: options.value})
  }
  render() {
    const {allowances} = this.state
    return (
      <div class="row">

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">New Allowance Type</h3>
            <p>Create New Allowance Types</p>
          </div>
          <div class="card">
            <h5 class="card-header">Create Allowances</h5>
            <div class="card-body">
              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                  <Input onchange={this.textChange} name="allowance" ph="Enter Allowance Type" label="ENTER ALLOWANCE TYPE" />
              <Choose options={[{ value: "percentage", label: "Percentage" }, { value: "fixed", label: "Fixed" }]} onchange={this.selected}/>
                  <button onClick={this.save} className="btn btn-secondary"> Save Allowance</button>
                      </div>
                      <div class="card-body border-top">
                        
                            </div>
                          </div>
                            </div>
                            
          <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title"> Allowances </h3>
            <p>You Can View And Delete Allowances Here</p>
            </div>
                            <div class="card">
            <h5 class="card-header">List Of Allowances <span class=" "></span></h5>
              <div class="card-body">
                <ul class="list-group list-group-flush">
                {
                  allowances.length < 1 ? <li class="list-group-item">No Allowances Available Add One On The Left Panel</li> : allowances.map(data => (
                    <li class="list-group-item">{allowances.indexOf(data)}) {data.ALLOWANCE_NAME} <button onClick={
                      (e, deleteId = data.ALLOWANCE_ID)=>{
                        const postData = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: "Bearer " + localStorage.getItem("Token"),
                            deleteId: deleteId
                          })
                        }

                        Api("/allowance/manage/delete", postData).then(data => {
                          this.InitFetch()
                        })
                      }
                      
                    } className="btn btn-danger btn-sm text-white float-right"><span className="fas fa-trash"></span></button></li>
                  ))
                }
                  
                 
                </ul>
              
             
                            </div>
          </div>
                            </div>
                      </div>
    )
  }
}

import React, { Component } from 'react'
import {Input} from "../../../components/Form"
import {Api} from "../../../helper/Api"

export default class Deduction extends Component {
  constructor()
  {
    super()
    this.state = {
      deductions: [],
      deduction: "",  
      alert: "",
      icon: "",
      message: "",
      mode: ""
    }
    this.textChange = this.textChange.bind(this)
    this.save = this.save.bind(this)
    this.InitFetch = this.InitFetch.bind(this)
    this.selected = this.selected.bind(this)
    }
  componentWillMount()
  {
    this.InitFetch()
  
  }
  
  InitFetch()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token") })
    }
    Api('/deductions/manage/list', data).then(data => {
      this.setState({deductions: data})
    })
  }
  textChange(e)
  {
    this.setState({[e.target.name]: e.target.value})
  }
  save()
  {
    
    const state = this.state
    if(state.deductions === "" )
    {
      this.setState({alert: "alert alert-warning", icon:"fas fa-exclamation", message: "Fill In The Field"});
    } else{
      this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Saving..." });
      const data = {
        method: 'POST',
        body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token"), deduction: state.deduction})
      }
      Api("/deductions/manage/add", data).then(data => {
        this.setState({alert: "alert alert-primary", icon:"fas fa-check", message: data.message})})
        this.InitFetch()
    }
  }

  selected(options)
  {
    this.setState({mode: options.value})
  }
  render() {
    const {deductions} = this.state
    return (
      <div class="row">

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">New Deductions Type</h3>
            <p>Create New Deductions Types</p>
          </div>
          <div class="card">
            <h5 class="card-header">Create Deductions</h5>
            <div class="card-body">
              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                  <Input onchange={(e)=>{
                    this.setState({
                      [e.target.name]: e.target.value
                    })
                  }} name="deduction" ph="Enter Deductions Type" label="ENTER ALLOWANCE TYPE" />
               <button onClick={this.save} className="btn btn-secondary"> Save Deductions</button>
                      </div>
                      <div class="card-body border-top">
                        
                            </div>
                          </div>
                            </div>
                            
          <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title"> Deductions </h3>
            <p>You Can View And Delete Deductions Here</p>
            </div>
                            <div class="card">
            <h5 class="card-header">List Of Deductions <span class=""></span></h5>
              <div class="card-body">
                <ul class="list-group list-group-flush">
                {
                  deductions.length < 1 ? <li class="list-group-item">No Deductions Available Add One On The Left Panel</li> : deductions.map(data => (
                    <li class="list-group-item">{deductions.indexOf(data)}) {data.DEDUCTION_NAME} <button className="btn btn-danger btn-sm text-white float-right" onClick={
                      (e, deductionId=data.DEDUCTION_ID)=>
                      {
                        const postData = {
                          method:'POST',
                          body: JSON.stringify({
                            token: "Bearer "+localStorage.getItem("Token"),
                            deleteId: deductionId
                          })
                        }
                        
                        Api('/deductions/manage/delete', postData).then(data =>{
                          this.setState({
                            alert: "allert alert-danger",
                            message: data.message
                          })
                          this.InitFetch()
                        })
                     
                      }
                    }><span className="fas fa-trash"></span></button></li>
                  ))
                }
                  
                 
                </ul>
              
             
                            </div>
          </div>
                            </div>
                      </div>
    )
  }
}

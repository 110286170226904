import React, { Component } from 'react'

import { Api } from '../../../helper/Api'
import { Input, Choose} from '../../../components/Form'


export default class Employee extends Component {
   constructor()
  {
    super()
    this.state = {
      employeeData: [],
      departments: [],
      loans: [],
      rate:"",
      empId: "",
      loanId: "",
      amount: "",
      alert: "",
      icon: "",
      message: "",
      time: "",
      empData: [],
      
    }
    this.Init = this.Init.bind(this)
    this.save = this.save.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.textChange = this.textChange.bind(this)
  }
  componentWillMount()
  {
    this.Init()
  }
  
  Init()
  {
    var data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }

    Api('/employees/manage/list', data).then(data => 
      {
        this.setState({employeeData: data});
      })
    Api('/loans/manage/list', data).then(data => {
      this.setState({ loans: data })
    })
  }


  save()
  {
    const state = this.state
    const data = {
      method: 'POST',
      body: JSON.stringify(
        {
          token: "Bearer "+localStorage.getItem("Token"),
          amount: state.amount,
          rate: state.rate,
          loanId: this.state.loanId,
          time: this.state.time,
          empId: state.empId,
         
        }
      )
    }

    Api("/loans/manage/allocate", data).then(data => {
      this.setState({
        alert: "alert alert-primary",
        message: data.message
      })
      this.Init()
    })
  }

  handleChange(e)
  {
    this.setState({
      loanId: e.value
    })
  }
  textChange(e)
  {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const {  loans, empData } = this.state
    const options = loans.map(data => (
      { value: data.LOAN_ID, label: data.LOAN_TYPE}
    ))

    return (
      <div>
           
        <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="section-block">
            <h5 class="section-title">Info</h5>
            <p>Manage Employee Loans</p>
          </div>
          {
            this.state.employeeData.length < 1 ? <b>No Employees Avaliable Please Add Employees</b> : <span></span>
          }
          <div className={this.state.alert}>{this.state.message}</div>
          <div class="accrodion-regular">
            <div id="accordion3">
              {
                this.state.employeeData.map(data => (
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" name={data.EMPLOYEE_ID} onClick={(e)=>{
                          this.setState({empId: data.EMPLOYEE_ID})
                         var fetchData = {
                           method: 'POST',
                           body:JSON.stringify({
                             token: "Bearer "+localStorage.getItem("Token"),
                             empId: data.EMPLOYEE_ID
                           })
                         }

                         Api('/loans/manage/one', fetchData).then(data => {this.setState({empData: data})})
                        }} data-target={"#"+data.EMPLOYEE_ID} aria-expanded="false" aria-controls="collapseSeven">
                            <button className="btn btn-xs btn-info"> SET LOANS</button>
                                               </button>
                        EMPLOYEE ID:{data.EMPLOYEE_ID} | EMPLOYEE NAME: {data.NAMES} 
                      </h5>

                     
                    </div>
                    <div id={data.EMPLOYEE_ID} class="collapse" aria-labelledby="headingSeven" data-parent="#accordion3">
                      <div class="row">

                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="section-block" id="basicform">
                            <h3 class="section-title">New Loans</h3>
                            <p>Manage Loans</p>
                          </div>
                          <div className={this.state.alert}>
                            <strong>{this.state.message}</strong>
                          </div>
                          <div class="card">
                            <h5 class="card-header">Set Loans</h5>
                            <div class="card-body">
                              <div class={this.state.alert}><span class={this.state.icon}></span> <strong>{this.state.message}</strong></div>
                              <Choose options={options} label="CHOOSE LOANS TYPE" onchange={this.handleChange} ph="Select LOANS TYPE" />
                              <Input onchange={this.textChange} name="amount" ph="Enter Amount Credited" type="number" />
                              <Input onchange={this.textChange} name="rate" ph="Enter Loan Rate" type="number" />
                              <Input onchange={this.textChange} name="time" ph="Enter Loan Deadline" type="number" />
                              
                              <button onClick={this.save} className="btn btn-secondary"> Save</button>
                            </div>
                            <div class="card-body border-top">

                            </div>
                          </div>
                        </div>

                        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class="section-block" id="basicform">
                            <h3 class="section-title">List  of Loans For {data.EMPLOYEE_ID} </h3>
                           
                          </div>
                          <div class="card">
                            <h5 class="card-header">List Of Loans <span class=" "></span></h5>
                            <div class="card-body">
                              <ul class="list-group list-group-flush">
                                {
                                  empData.length < 1 ? <li class="list-group-item">No LOANS Available Add One On The Left Panel</li> : empData.map(data => (
                                    <li class="list-group-item">{empData.indexOf(data)}) LOAN :{data.LOAN_TYPE}, AMOUNT CREDITED :{data.LOAN_AMOUNT} , PAYMENT RATE (per month) {data.LOAN_RATE}% , TIME {data.TIME} MONTHS , START: {data.ACT_START} , DEADLINE: {data.ACT_STOP} <button onClick={
                                      (e , del=data.ID) => {
                                        const postData = {
                                          method: 'POST',
                                          body: JSON.stringify({
                                            token: "Bearer "+localStorage.getItem("Token"),
                                            del: del
                                          })
                                        }
                                         Api("/loans/manage/delemp", postData).then(data => {
                                           this.setState({
                                             alert: "alert alert-danger",
                                             message: data.message
                                           })
                                           this.Init()
                                         })
                                      }

                                     
                                    } className="btn btn-danger btn-sm text-white float-right"><span className="fas fa-trash"></span></button></li>
                                  ))
                                }


                              </ul>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
                         </div>
          </div>
        </div>

      </div>
    )
  }
}

import { getSessionPayload } from "../../helper/Session";

var modules = [{
    category: "MODULES", modules: [{ id: 4,name: "DIVISIONS AND DEPARTMENTS", icon: "code-branch", actions:[{link: "/divisions/new", text: "DIVISION MANAGEMENT"},
    {link: "/departments/new", text: "DEPARTMENT MANAGEMENT"}]},
    { id: 1, name: "EMPLOYEE MANAGEMENT", 
    icon: "users", actions: [{ link: "/employee/register", text: "REGISTER EMPLOYEE" }, { link: "/employee", text: "MANAGE EMPLOYEES" }] },
    { id: 11, name: "TAX MANAGEMENT", icon: "balance-scale", actions:[{link: "/tax/relief", text: "PERSONAL RELIEF"}, {link:"/tax/manage",
    text:"TAX MANAGEMENT"}, {link:"/tax/rules", text:"TAXATION RATES MANAGEMENT"}, {link:"/tax/p9", text:"GENERATE P9 FORMS"},
     ] }, 
     { id: 5, name: "DEDUCTIONS MANAGEMENT", icon: "minus-square", actions: [{ link: "/deductions/types", text: "SET DEDUCTION TYPES" },
             { link: "/deductions/rates", text: "DEDUCTION RATES" }, { link: "/deductions/employee", text: "OTHER DEDUCTION" },] },
        { id: 6, name: "ALLOWANCES MANAGEMENT", icon: "plus-square", actions: [{ link: "/allowances/types", text: "SET ALLOWANCE TYPES" },
         { link: "/allowances/employee", text: "SET EMPLOYEE ALLOWANCE" }] },        
        { id: 7, name: "LOANS MANAGEMENT", icon: "balance-scale", actions: [{ link: "/loan/types", text: "SET LOAN TYPES" },
         { link: "/loan/employee", text: "SET EMPLOYEE LOANS" }] },
         { id: 10, name: "BASIC SALARY", icon: "leaf", actions: [{ link: "/salary/departments", text: "SET EMPLOYEE SALARY" }] },
         { id: 2, name: "PAYROLL MANAGEMENT", icon: "money-bill-alt", actions:[{link: "/payroll/company", text: "GENERATE COMPANY PAYROLL"}] },
         { id: 3, name: "EXTRAS", icon: "calendar-check", actions: [{link: "/attendance/verify", text: " LEAVE / OFF DAY"}, 
         {link: "/share", text: " SHARE CENTER"}] },
        { id: 8, name: "REPORTS AND SUMMARY", icon: "folder", actions: [{ link: "/reports/payslip", text: "VIEW PAYSLIPS" },
         { link: "/reports/employees", text: "EMPLOYEES REPORT" }, { link: "/reports/payroll", text: "VIEW  PAYROLL" },
          { link: "/reports/deductions", text: "VIEW DEDUCTIONS" }, { link: "/reports/allowances", text: "VIEW ALLOWANCE" }] },
        { id: 9, name: "PREFERENCES", icon: "cogs", actions: [{ link: "/settings/access", text: "ACCOUNTS AND PERMISSIONS" }, 
        { link: "/settings/workspace", text: "WORKSPACE SETTINGS" }] }
        ]
     }]

     var modulex = modules[0].modules

        const assign = [{
            "1":modulex[0],
            "2":modulex[1],
            "3":modulex[2],
            "4":modulex[4],
            "5":modulex[5],
            "6":modulex[6],
            "7":modulex[7],
            "8":modulex[8],
            "9":modulex[9],
            "10":modulex[3]
            
        }]
     

function Access(){
    var group, privillages
    if(getSessionPayload() === null)
    {
        group = "unknown"
        privillages = []
    } else {
     group = getSessionPayload().rank 
     privillages  = getSessionPayload().privillages
    }
    var access=[{category: "MODULES", modules: []}]
    if(group === "Administrator" || group === "Employee")
    {
        if(group === "Administrator")
        {
            access = modules
        }else{
          access = [{
              category: "EMPLOYEE SELF SERVICE", modules: [
                  { id: 1, name: "SERVICES", icon: "users", actions: [{ link: "/self/payslip", text: "LAST PAYSLIP" }, { link: "/self/leave", text: "REQUEST LEAVE / OFF DAY" }, { link: "/self/documents", text: "MY DOCUMENTS" }, { link: "/share", text: "SHARE CENTER" },{ link: "/self/p9_forms", text: "P9 FORM" }, ] },
            ]
          }]  
        }
    } else {
        privillages.map(data => {access[0].modules.push(assign[0][data]); return true})
    }
   
    
    return access
}
modules = Access()

export {modules}
import React, { Component } from 'react'

import Select from 'react-select'
import { Api } from '../../helper/Api';
import { empty } from '../../helper/validator';


class Home extends Component {

  constructor()
  {
    super()
    this.state = {
      company: [],
      organizationId: "",
      username: "",
      password: "",
      alert: "",
      icon: "",
      message: ""
    }
    this.getCompany = this.getCompany.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.changeText = this.changeText.bind(this)
    this.save = this.save.bind(this)
  }

  componentWillMount()
  {
    this.getCompany()
  } 

  getCompany()
  {
    this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Please wait, Loading Companies..." })

    const data = {
      method: 'POST'
    }

    Api('/workspace/manage/list', data).then(data => {
      this.setState({company: data, alert: "", icon: "", message:""})
    })

  }

  changeText(e) {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  save(e) {

    e.preventDefault()
    console.log(this.state);

    if (empty(this.state.organizationId) || empty(this.state.username) || empty(this.state.password)) {
      //proceed
      this.setState({ alert: "alert alert-danger", icon: "fas fa-exclamation", message: "Please fill in all fields !" })

    }
    else {
      //proceed
        this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Checking Credentials..." })
        const data = {
          method: 'POST',
          body: JSON.stringify({
            organizationId: this.state.organizationId,
            username: this.state.username,
            password: this.state.password
          })
        }
        Api('/auth/account/login', data).then(data => {
          if (data.status === "success") {
            this.setState({ alert: "alert alert-primary", icon: "fas fa-check", message: data.message })
            localStorage.setItem("Token", data.token)
            window.location.href="/dashboard"

          }
          else {
            this.setState({ alert: "alert alert-danger", icon: "fas fa-exclamation", message: data.message })

          }
        })
    }
  }

  handleSelection(e)
  {
    this.setState({organizationId: e.value})
  }
  render() {
  const options = this.state.company.map(data => ({value: data.ORGANIZATION_ID, label:data.ORGANIZATION_NAME}))
    return(
      <div class="splash-container">
        <div class="card ">
              <div class="card-body">
              <div class="card-title text-center"><b>AUTHENTICATION</b></div>
              <form onSubmit={this.save}>
              <div className={this.state.alert}>
                <strong><i class={this.state.icon }></i></strong> <small>{this.state.message}</small>
              </div>
              <div className="form-group">
              <label htmlFor="">COMPANY</label>
              <Select options={options} onChange={this.handleSelection} />
              </div>
                <div class="form-group">
                <label htmlFor="">USERNAME</label>
                  <input class="form-control form-control-lg" id="username" onChange={this.changeText} type="text" placeholder="Username" autocomplete="off"/>
                    </div>
                  <div class="form-group">
                  <label htmlFor="">PASSWORD</label>
                    <input class="form-control  form-control-lg" onChange={this.changeText} id="password" type="password" placeholder="Password"/>
                    </div>

                      <div class="text-center">
                <button type="submit" class="btn btn-primary btn-lg btn-bloc">SIGN IN</button></div>
                </form>
                  </div>
          <div class="card-footer bg-white p-0text-center  ">
                    <div class="card-footer-item card-footer-item-bordered">
              <a href="https://wesempire.co.ke/submit-ticket/" class=" ">Request WorkSpace</a></div>
                  </div>
                </div>
            
    </div>
    )

    
  }
}

export default Home;
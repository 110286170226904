import React, { Component } from 'react'
import OverView from '../../components/OverView';
import PageHeader from '../../components/PageHeader';
import { Api } from '../../helper/Api';
import { getSessionPayload } from '../../helper/Session';
import Share from './Share';
import {Chart} from 'primereact/chart';
export default class Dashboard extends Component {
  constructor()
  {
    super()
    this.state = {
      checkpoints: {}
    }
  }

  componentWillMount()
  {
    this.getChekpoints()
  }

  getChekpoints()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("Token")
      })
    }
    Api('/checkpoints/dashboard/status', data).then(
      data => {
        this.setState({
          checkpoints: data.checkpoint
        })        
      }
    )
  }
  
  render() {
    const {checkpoints} = this.state
    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
          {
              label: 'EMPLOYEE SALARY',
              data: [0, 0, 0, 81, 0 , 55,  90 ],
              fill: false,
              backgroundColor: '#42A5F5',
              borderColor: '#42A5F5'
          },
          {
              label: 'DEDUCTIONS',
              data: [0, 0, 0, 19, 56, 27, 40],
              fill: false,
              backgroundColor: '#66BB6A',
              borderColor: '#66BB6A'
          }
      ]
  };
    return (
      <div className="dashboard-wrapper bg-white">
       <div className="dashboard-ecommerce">
       <div className="container-fluid dashboard-content ">
           <PageHeader title="OVERVIEW"  />
            <div class="row">
            {getSessionPayload().rank === "Employee" ? <span></span> :  <OverView text="EMPLOYEES" value={checkpoints.employees} icon="user" bg='info'/>}
              <OverView text="DEPARTMENTS" value={checkpoints.departments} icon="cubes" bg='warning' />
              <OverView text="DIVISIONS" value={checkpoints.divisions} icon="seedling" bg='success' />
              
                            </div>
                            <div>
                              <h3 className="h3">YEARLY COMPANY EXPENDITURE</h3>
                              <Chart type="line" data={data} height="100px" />
                            </div>
                            <br/>
                           
       </div>
       </div>
      </div>
    )
  }
}

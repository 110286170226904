import React, { Component } from 'react'
import { Api } from '../../../helper/Api';
import {Input , Choose} from '.././../../components/Form'

export default class Departments extends Component {
  constructor() {
    super()
    this.state = {
      divisionId: "",
      department: "",
      departments:[],
      employeeData: [],
      alert: "",
      icon: "",
      message: "",
      year: "",
      month: ""
    }
    this.textChange = this.textChange.bind(this)
    this.save = this.save.bind(this)
    this.InitFetch = this.InitFetch.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  componentWillMount() {
    this.InitFetch()

  }

  InitFetch() {

  }
  textChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  save() {

    const state = this.state
    if (state.division === "") {
      this.setState({ alert: "alert alert-warning", icon: "fas fa-exclamation", message: "Fill In The Field" });
    } else {
      this.setState({ alert: "alert alert-primary", icon: "fas fa-spinner fa-spin", message: "Saving..." });
      const data = {
        method: 'POST',
        body: JSON.stringify({ token: "Bearer " + localStorage.getItem("Token"), division: state.divisionId, department: state.department })
      }
      Api("/departments/manage/add", data).then(data => {
        this.setState({ alert: "alert alert-primary", icon: "fas fa-check", message: data.message })
      })
      this.InitFetch()
    }
  }
  handleChange(selected)
  {
    this.setState({divisionId: selected.value})
  }
  render() {
    const { employeeData } = this.state
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({ value: month, label: month }))
    
    return (
      <div class="row">

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section-block" id="basicform">
            <h3 class="section-title">Manage Salary</h3>
            <p>Click To Edit the Data Sheet</p>
          </div>
          <div class="card">
            <h5 class="card-header">Control Salary</h5>
            <div class="card-body">
              < div className="col-md-6" >
                <Choose options={monthOpt} label="Choose Month" onchange={
                  e => {
                    this.setState({
                      month: e.value
                    })

                    console.log(this.state);

                  }
                } />
                <Input label="Choose Year" ph="Year" onchange={
                  e => {
                    this.setState({
                      year: e.target.value
                    })
                  }
                } />

                <button className="btn btn-primary" onClick={
                  e => {
                    const postData = {
                      method: 'POST',
                      body: JSON.stringify({
                        token: "Bearer " + localStorage.getItem('Token'),
                        month: this.state.month,
                        year: this.state.year
                      })
                    }
                    Api('/salary/employee/list', postData).then(data => {
                      this.setState({ employeeData: data })
                    })
                  }
                }>Get Salaries</button>
              </div >
              <hr/>
             <table className="table table-bordered table-hover table-editable">
              <tr>
                <th>EMPLOYEE ID</th>
                <th>EMPLOYEE NAME</th>
                  <th>BASIC SALARY</th>
              </tr>
              <tbody>
                {
                  employeeData.map(data => (
                    <tr>
                      <td>
                        {
                          data.EMPLOYEE_ID
                        }
                      </td>

                      <td>
                        {
                          data.NAMES
                        }
                      </td>

                      <td id={data.EMPLOYEE_ID} contentEditable onBlur={
                        (e)=>{
                          var postData ={
                            method: 'POST',
                            body: JSON.stringify(
                              {
                                token: "Bearer "+localStorage.getItem("Token"),
                                empId: e.target.id,
                                amount: e.currentTarget.textContent,
                                month: this.state.month,
                                year: this.state.year
                              }
                            )
                          }

                          Api('/salary/employee/add', postData).then(data=>{console.log(data);
                          })
                        }
                      }>
                        {
                          data.AMOUNT
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
             </table>
             </div>
            <div class="card-body border-top">

            </div>
          </div>
        </div>

        
      </div>
    )
  }
}

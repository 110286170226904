import React, { Component } from 'react'
import ReactToPrint from "react-to-print";
import { Api, api } from '../../../helper/Api';
import {Choose, Input} from '../../../components/Form'
class Report extends Component
{
  render()
  {
    return(
      <div>
        <div class="row">
                        {
                            this.props.sheet.map(data => (
                                <div class="offset-xl-2 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="card">
                                <div class="card-header p-4">
                                {this.props.company.details.map(data => (
                                  <img height="50" width="50" src={`${api}/uploads/${data.LOGO}`} alt=""/>
                                ))}
                                    <div class="float-right">  <h3><a class="pt-2 d-inline-block" href="/" >{this.props.company.orgName}</a></h3>
                                    
                                </div>
                                <h4 class="mb-0">PAYSLIP</h4>
                                   {this.props.date}
                                <div class="card-body">
                              </div>
                                   <h5>Employee Id: {data.id} </h5><br/>
                                   <h5>Employee Name: {data.name}</h5>

                                   <hr/>

                                   <h3>Deductions</h3>
                                   <br/>
                                   
                                    {data.deductionList.map(data => (
                                      <div className="row">
                                        <b className="col-md-6">{data.deductionName}:</b> <strong className="col-md-6">KES {data.deducationType}</strong>
                                   </div>
                                    ))}
                                  
                                   <hr/>
                                   <h3>Other Deductions</h3>

                                  {data.custom.map(data=>(
                                    <div className="row">
                                      <b className="col-md-6">{data.DEDUCTION_NAME}</b> <strong className="col-md-6">{`KES ${data.AMOUNT}`}</strong>
                                    </div>
                                  ))}
                                   <hr/>
                                   <h3>Allowances</h3> <br/>
                                   {
                                      
                                       data.allowanceList.map(data => (
                                         <div className="row">
                                            <b className="col-md-6">{data.ALLOWANCE_NAME}:</b> <strong className="col-md-6"> KES {data.AMOUNT}</strong> <br />  </div>
                                       ))
                                      
                                   }
                                   <hr/>
                                   <h3>Sub Totals</h3>
                                   <div className="row">
                                      <h5 className="col-md-6">Basic Salary:</h5> <strong className="col-md-6">KES {data.basicSalary}</strong>
                                      <h5 className="col-md-6">Total Deductions:</h5><strong className="col-md-6"> KES {data.deduction} </strong>
                                      <h5 className="col-md-6">Total Allowance:</h5><strong className="col-md-6"> KES {data.allowance}</strong>
                                      <h5 className="col-md-6">Gross Pay:</h5> <strong className="col-md-6"> KES {data.gross}</strong>
                                   </div>
                                    
                                   
                                </div>
                                <div class="card-footer bg-white">
                                <p class="mb-0">{
                                      this.props.company.details.map(data => (
                                        <span>Location: {data.LOCATION} CONTACT: {data.CONTACT}</span>
                                      ))
                                    }</p></div>
                            </div>
                        </div>
                            ))
                        }
                    </div>
      </div>
    )
  }
}
export default class Payslip extends Component {
    constructor()
    {
      super()
      this.state = {
        company: {details: [], orgName:""},
        date: new Date(),
        sheet: [],
        gross: ""
      }
  
      this.mountFunction = this.mountFunction.bind(this)
    }
  
    componentWillMount()
    {
      this.mountFunction()
    }
    mountFunction()
    {
      //get organization Details
      const postData = {
        method: 'POST',
        body: JSON.stringify({
          token: "Bearer "+localStorage.getItem('Token')
        })
      }
      Api("/workspace/manage/find", postData).then(
        data => {
          this.setState({
            company: data
          })
        }
      )
  
      //get Payroll Details
    }
  render() {
    
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    var monthOpt = months.map(month => ({ value: month, label: month }))
    return (
      <div>
         <ReactToPrint
          trigger={() =>  <button className="btn btn-info">PRINT</button>}
          content={() => this.componentRef}
        />
        
        < div className="col-md-6" >
          <Choose options={monthOpt} label="Choose Month" onchange={
            e => {
              this.setState({
                month: e.value
              })

              console.log(this.state);

            }
          } />
          <Input label="Choose Year" ph="Year" onchange={
            e => {
              this.setState({
                year: e.target.value
              })
            }
          } />

          <button className="btn btn-primary" onClick={
            e => {
              const postData = {
                method: 'POST',
                body: JSON.stringify({
                  token: "Bearer " + localStorage.getItem('Token'),
                  month: this.state.month,
                  year: this.state.year
                })
              }
              Api('/payroll/manage/payslip', postData).then(data => {
                this.setState({ sheet: data.payrollData, gross: data.grossTotal })
              })
            }
          }>Get Slips</button>
        </div >
        <hr/>
       <Report date={this.state.date.toString()} sheet={this.state.sheet} gross={this.state.gross} company={this.state.company} ref={el => (this.componentRef = el)} />
           
                
      </div>
    )
  }
}

import React, { Component } from 'react'

import Navbar from '../components/Navbar';
import {BrowserRouter as Router} from 'react-router-dom'
import Home from '../pages/public/Home';
import Sidenav from '../components/Sidenav';
import Register from './public/Register';
import { loggedIn } from '../helper/Session';
import PrivateRoute from '../helper/SecureRoute';
import PublicRoute from '../helper/PublicRoute';
import Dashboard from './app/Dashboard';
import Employee from './app/Employee';
import Divisions from './app/Divisions';
import Department from './app/Department';
import Payroll from './app/Payroll';
import Deductions from './app/Deductions';
import ALLOWANCES from './app/Allowances';
import Loans from './app/Loans';
import Reports from './app/Reports';
import Settings from './app/Settings';
import Salary from './app/Salary';
import Leave from './app/Leave';
import Account from './app/Account';
import Self from './app/Self';
import Share from './app/Share';
import Taxation from './app/Taxation';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const Authed = loggedIn();

export default class Entry extends Component {
  render() {
    return (
   <Router>
      
        <div className={Authed === true ? "dashboard-main-wrapper" : "container-fluid page-body-wrapper full-page-wrapper auth-page"}>
       <Navbar />
          
            <div className="inner-wrapper">
              <Sidenav />
       <PublicRoute exact path="/" component={Home} />
       <PublicRoute path="/register" component={Register} />
       <PrivateRoute path="/dashboard" component={Dashboard} />
       <PrivateRoute path="/attendance" component={Leave} />
       <PrivateRoute path="/employee" component={Employee} />
       <PrivateRoute path="/divisions" component={Divisions}/>
       <PrivateRoute path="/departments" component={Department} />
       <PrivateRoute path="/payroll" component={Payroll} />
       <PrivateRoute path="/deductions" component={Deductions} />
       
            <PrivateRoute path="/deduction" component={Deductions} />
            <PrivateRoute path="/allowances" component={ALLOWANCES} />
            <PrivateRoute path="/loan" component={Loans} />
            <PrivateRoute path="/reports" component={Reports} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/salary" component={Salary} />
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/self" component={Self} />
            <PrivateRoute path="/share" component={Share} />
            <PrivateRoute path="/tax" component={Taxation} />
            
            </div>
       </div>

   </Router>
    )
  }
}

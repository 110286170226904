import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom'
import EmployeeRegistration from './EmployeeModule/EmployeeRegistration';
import PageHeader from '../../components/PageHeader';
import Employees from './EmployeeModule/Employees';

export default class Employee extends Component {
  render() {
    return (
      <div className="dashboard-wrapper bg-white">
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                    <PageHeader title="EMPLOYEE MANAGEMENT MODULE" />
                <Switch>
                    <Route path={this.props.match.path+"/register"} component={EmployeeRegistration}  />
                    <Route exact path={this.props.match.path} component={Employees} />
                </Switch>
            </div>
        </div>
      </div>
    )
  }
}
